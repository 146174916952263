import * as actionTypes from './actionTypes';
import axios from '../../lib/axios/axios';
import axiosGolang from 'lib/axios/axios-golang';
import { getChannelContextMsg, isKasbon, isObjectEmpty } from 'helpers/Utility';
import {
  USERS,
  USERSV2,
  CODE_REFERENCES,
  PROVINCE,
  COMPANY_INFO,
  VERIFY_PAYROLL,
  FIELD_OF_WORKS,
  FIELD_OF_BUSINESS,
  PARTNER_ID_GENERAL,
  FIELD_JOB_TITLE,
  FIELD_DAILY_NORMAL_TRANSACTION,
  MAINTENANCE,
  STANDARD_TEXT
} from '../../common/config';
import {
  setHomePath,
  setError,
  setIsLogin,
  setOauthToken,
  setIsTncAgreement,
  setUpdateAgreement
} from './auth';
import {
  setTimer,
  startTimer,
  setCreditScoreResult,
  setOffering1,
  setOffering2,
  setSelectedOffering,
  setloanAmountSelected,
  getLoanAsync,
  stopTimer,
  ekycCheckingStatus,
  setRegistrationStatus,
  setRegistrationStatusIncoming,
  setRayaAccountNumber,
  setRayaAccountName,
  setBriAccountNumber
} from './disburse';
import { getJsonStringify, getChannelContextCode } from '../../helpers/Utility';
import { statusPayment, lastPayment } from './payment';
import { sendEvent, sendException } from 'helpers/analytics';
import {
  COMPANY_NAME_ALTERNATIVE,
  OCH_DATE_FORMAT_SUBMISSION,
  RETIRED_EMPLOYEE_AGE,
  SCHEME_CODE_KASBON,
  SCHEME_CODE_PMI,
  SCHEME_CODE_PNANG,
  SCHEME_CODE_PNNEW
} from 'common/constants';
import Moment from 'moment';

export const loading = val => {
  return {
    type: actionTypes.APP_LOADING,
    loading: val
  };
};

let controller = new AbortController();

export const applicationInquiry = (auth, params) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/applicationInquiry?recordCount=1';

    axiosGolang
      .get(url, { headers: { Authorization: auth.token } })
      .then(res => {
        const appStatus = res.data.applicationStatus.cmCode;
        const ekycStatus = res.data.EkycStatus;
        dispatch(setApplicationStatus(appStatus));
        dispatch(setAppInqRes(res.data));
        res.data.ktpNum && dispatch(setEktp(res.data.ktpNum));
        res.data.approvalDetails &&
          dispatch(setApprovalDetails(res.data.approvalDetails));
        res.data.approvalDetails &&
          dispatch(setCustomerName(res.data.approvalDetails.nama));
        res.data.incompleteApplicationDetails &&
          dispatch(setIncompleteApp(res.data.incompleteApplicationDetails));
        res.data.bankCode && dispatch(setBankCode(res.data.bankCode));
        dispatch(setSecondApply(res.data.isSecondApply));
        res.data.isCompanyUpdated &&
          dispatch(setIsCompanyUpdated(res.data.isCompanyUpdated));
        res.data.isCustomerConsentAgreement &&
          dispatch(setIsCustomerConsent(res.data.isCustomerConsentAgreement));
        res.data.isWordingPmi &&
          dispatch(setIsWordingPmi(res.data.isWordingPmi));

        const isTncAgreement = res.data.isTncAgreement || '';

        if (!isTncAgreement || isTncAgreement !== 'Y') {
          dispatch(setIsTncAgreement(isTncAgreement));
          dispatch(setUpdateAgreement(true));
        } else dispatch(setIsTncAgreement(isTncAgreement));

        if (res.data.accountInformation) {
          dispatch(setAccountInformationInq(res.data.accountInformation));

          if (res.data.accountInformation.registrationRayaSavingStatus)
            dispatch(
              setRegistrationStatus(
                res.data.accountInformation.registrationRayaSavingStatus
              )
            );
          if (res.data.accountInformation.registrationRayaSavingStatusIncoming)
            dispatch(
              setRegistrationStatusIncoming(
                res.data.accountInformation.registrationRayaSavingStatusIncoming
              )
            );
          if (res.data.accountInformation.acctRayaSavingExist)
            dispatch(
              setAccountRayaSavingExist(
                res.data.accountInformation.acctRayaSavingExist
              )
            );
          if (res.data.accountInformation.disbursementAccountNumber)
            dispatch(
              setBriAccountNumber(
                res.data.accountInformation.payrollAccountNumber
              )
            );
          if (res.data.accountInformation.disbursementAccountNumber)
            dispatch(
              setRayaAccountNumber(
                res.data.accountInformation.disbursementAccountNumber
              )
            );
          if (res.data.accountInformation.accountNameRaya)
            dispatch(
              setRayaAccountName(res.data.accountInformation.accountNameRaya)
            );

          if (res.data.accountInformation.acctCreatedFromSaving)
            dispatch(
              setAcctCreatedFromSaving(
                res.data.accountInformation.acctCreatedFromSaving
              )
            );
        }

        if (appStatus.includes('_SAVED')) {
          dispatch(setHomePath('/continue-apply'));
          dispatch(loading(false));
        }
        if (appStatus.includes('CR_SCORE')) {
          dispatch(setHomePath('/credit-scoring'));
          if (appStatus === 'CR_SCORE_SUB') {
            localStorage.setItem('setCS', 1);
            dispatch(
              setTimer({
                minutes: res.data.timeLeftForScoreCompletion,
                seconds: 0
              })
            );
            dispatch(startTimer());
            if (params && !params.isCounting) {
              dispatch(setCreditScoreResult(''));
              dispatch(startTimer());
            }
          }
          if (appStatus === 'CR_SCORE_APR') {
            localStorage.removeItem('operationalDate');
            localStorage.removeItem('setCS');
            localStorage.removeItem('setCSFromFormFill');
            const isKtpVerified =
              res.data.approvalDetails &&
              res.data.approvalDetails.isKTPVerified;
            const alternative =
              res.data.approvalDetails && res.data.approvalDetails.alternative;
            const plafond1 =
              res.data.approvalDetails && res.data.approvalDetails.plafond1;
            const plafond2 =
              res.data.approvalDetails && res.data.approvalDetails.plafond2;
            const tenure1 =
              res.data.approvalDetails && res.data.approvalDetails.tenor1;
            const tenure2 =
              res.data.approvalDetails && res.data.approvalDetails.tenor2;
            const installment1 =
              res.data.approvalDetails &&
              res.data.approvalDetails.oneMonthPayDetails
                .oneMonthPayForPlafond1;
            //handling array monthlyPayDetails jika kosong.
            const installment2 =
              res.data.approvalDetails &&
              res.data.approvalDetails.monthlyPayDetails &&
              res.data.approvalDetails.monthlyPayDetails.find(
                i => i.loanTenor === Number(tenure2)
              ) &&
              res.data.approvalDetails.monthlyPayDetails.find(
                i => i.loanTenor === Number(tenure2)
              ).monthlyPayment;
            const approvalAmount =
              res.data.approvalDetails &&
              res.data.approvalDetails.approvalAmount;
            const interestAmount =
              res.data.approvalDetails &&
              res.data.approvalDetails.interestAmount1;
            const feeAmountForPlanfond1 =
              res.data.approvalDetails &&
              res.data.approvalDetails.feeAmountForPlanfond1;
            const feeAmountForPlanfond2 =
              res.data.approvalDetails &&
              res.data.approvalDetails.feeAmountForPlanfond2;

            //plafond1 is applied loan amount
            //plafond2 is an option/offering from och
            //if OCH return plafond2 that is greater than plafond1 then, the user will get a special offering
            if (
              plafond1 === 0 &&
              isKtpVerified === 'Y' &&
              params &&
              params.checking === true
            ) {
              /***
               * not used
               */
              dispatch(
                setSelectedOffering({
                  plafond: plafond2,
                  tenure: tenure2
                })
              );
              dispatch(setCreditScoreResult('user-declined'));
              dispatch(setTimer({ minutes: '', seconds: '' }));
              dispatch(stopTimer());
            } else if (alternative && isKtpVerified === 'Y') {
              /**
               * Payroll Alternative
               */
              if (isKasbon(res.data.schemeCode)) {
                dispatch(
                  setSelectedOffering({
                    plafond: alternative[0]?.plafond,
                    tenure: alternative[0]?.tenor,
                    installment: alternative[0]?.monthlyPayment,
                    interestAmount: alternative[0]?.interestAmount,
                    processFee: alternative[0]?.feeAmount
                  })
                );
                dispatch(setCreditScoreResult('approved'));
              } else {
                dispatch(
                  setOffering1({
                    alternative: alternative
                  })
                );
                dispatch(setCreditScoreResult('user-declined-alternatif'));
              }
              dispatch(setTimer({ minutes: '', seconds: '' }));
              dispatch(stopTimer());
            } else if (
              (plafond1 > 0 && plafond2 > 0 && plafond2 > plafond1) ||
              alternative
            ) {
              /**
               * Privilege Alternative (temporary not used)
               */
              if (isKasbon(res.data.schemeCode)) {
                dispatch(
                  setSelectedOffering({
                    plafond: alternative[0]?.plafond,
                    tenure: alternative[0]?.tenor,
                    installment: alternative[0]?.monthlyPayment,
                    interestAmount: alternative[0]?.interestAmount,
                    processFee: alternative[0]?.feeAmount
                  })
                );
                dispatch(setCreditScoreResult('approved'));
              } else {
                dispatch(
                  setOffering1({
                    plafond: plafond2,
                    tenure: tenure2,
                    installment: installment2
                  })
                );
                dispatch(
                  setOffering2({
                    plafond: plafond1,
                    tenure: tenure1,
                    installment: installment1
                  })
                );
                dispatch(setCreditScoreResult('offering'));
              }
              dispatch(setTimer({ minutes: '', seconds: '' }));
              dispatch(stopTimer());
            } else {
              /**
               * Normal flow CS */
              if (isKasbon(res.data.schemeCode)) {
                if (plafond1 > 0) {
                  dispatch(
                    setSelectedOffering({
                      plafond: plafond1,
                      tenure: tenure1,
                      installment: installment1,
                      interestAmount: interestAmount,
                      processFee: feeAmountForPlanfond1
                    })
                  );
                } else {
                  dispatch(
                    setSelectedOffering({
                      plafond: plafond2,
                      tenure: tenure2,
                      installment: installment1,
                      interestAmount: interestAmount,
                      processFee: feeAmountForPlanfond2
                    })
                  );
                }
              } else {
                if (plafond1 > 0) {
                  dispatch(
                    setSelectedOffering({
                      plafond: plafond1,
                      tenure: tenure1
                    })
                  );
                } else {
                  dispatch(
                    setSelectedOffering({
                      plafond: plafond2,
                      tenure: tenure2
                    })
                  );
                }
              }

              dispatch(setCreditScoreResult('approved'));
              dispatch(setTimer({ minutes: '', seconds: '' }));
              dispatch(stopTimer());
            }

            if (approvalAmount > 0) {
              dispatch(setHomePath('/continue-disburse'));
            }
          }
          if (appStatus === 'CR_SCORE_REJ') {
            dispatch(
              setNextDate(res.data.rejectionDetails.nextEligibilityDate)
            );
            dispatch(
              setResponseCodeReject(res.data.rejectionDetails.responseCode)
            );
            dispatch(setCreditScoreResult('rejected'));
            if (localStorage.getItem('documentDetails'))
              localStorage.removeItem('documentDetails');
            dispatch(setTimer({ minutes: '', seconds: '' }));
            dispatch(stopTimer());
          }

          dispatch(loading(false));
        }

        if (appStatus === 'KTP_REJECT') {
          dispatch(setCreditScoreResult('ktp-reject'));
          dispatch(setHomePath('/credit-scoring'));
        }

        if (appStatus === 'DISB_ACC_CONF' && ekycStatus === 'P') {
          dispatch(
            ekycCheckingStatus({
              ...auth,
              applicationId: res.data.applicationId
            })
          );
        }

        if (
          appStatus === 'DISB_ACC_CONF' ||
          appStatus === 'EKYC_COM' ||
          appStatus === 'RE_EKYC' ||
          appStatus === 'RE_EKYC_COM' ||
          appStatus === 'DOCUMENT_SIGN'
        ) {
          dispatch(setHomePath('/continue-disburse'));
          dispatch(loading(false));
        }
        if (appStatus === 'DOCUMENT_SIGNED') {
          localStorage.removeItem('disburseLifetime');
          localStorage.removeItem('countHitButton');
          dispatch(setHomePath('/continue-disburse'));
          dispatch(loading(false));
        }
        if (appStatus === 'DISB_IN_PROCESS') {
          dispatch(setHomePath('/continue-disburse'));
          if (!getState().loanApply.disburseLifetime) {
            dispatch(
              setDisburseLifetime(
                res.data.disbursementDetails.disburseLifetime
                  ? res.data.disbursementDetails?.disburseLifetime
                  : 0
              )
            );
          }
          dispatch(loading(false));
        }
        if (appStatus === 'DIG_SIGN_COM') {
          dispatch(setHomePath('/continue-disburse'));
          dispatch(
            setloanAmountSelected(
              res.data.incompleteApplicationDetails.approvalAmount
            )
          );
          dispatch(loading(false));
        }

        if (appStatus === 'LOAN_CREATED') {
          if (params?.checkingStatus) {
            params.checkingStatus();
            return;
          }
          localStorage.removeItem('disburseLifetime');
          localStorage.removeItem('countHitButton');
          dispatch(
            getLoanAsync(
              {
                phoneNo: auth.phoneNo,
                token: auth.token
              },
              {
                action: () => dispatch(setHomePath('/disburse-fin'))
              }
            )
          );
        }
        if (appStatus === 'LOAN_PAID') {
          dispatch(loading(false));
          if (
            localStorage.getItem('paymentStatus') ||
            localStorage.getItem('lastPayment')
          ) {
            dispatch(statusPayment(''));
            dispatch(lastPayment(''));
            localStorage.removeItem('paymentStatus');
            localStorage.removeItem('lastPayment');
          }
          localStorage.removeItem('freezeDateSuccess');
        }
        dispatch(setApplicationId(res.data.applicationId));
        if (appStatus === 'APP_EXPIRED') {
          dispatch(loading(false));
          res.data.previousLoanPaidAppId &&
            dispatch(setApplicationId(res.data.previousLoanPaidAppId));
        }
        if (appStatus === 'PAYROLL_REJ') {
          dispatch(setHomePath('/home'));
          dispatch(loading(false));
          dispatch(setNextDate(res.data.payrollrejdetails.nextEligibilityDate));
        }
        if (appStatus === 'PAYROLL_APP') {
          dispatch(setHomePath('/continue-apply'));
          dispatch(loading(false));
        }
        if (appStatus === 'APP_CREATED') {
          dispatch(setHomePath('/home'));
          dispatch(loading(false));
        }
        if (appStatus === 'USR_REJECT') {
          dispatch(setHomePath('/home'));
          dispatch(loading(false));
          dispatch(setNextDate(res.data.rejectionDetails.nextEligibilityDate));
        }
      })
      .then(() => {
        if (!params) return;
        params && params.action && params.action();
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 401) dispatch(setError(''));
          dispatch(loading(false));
        }

        if (!err.response) {
          dispatch(loading(false));
          dispatch(setError('connection'));
          dispatch(setOauthToken('', ''));
          dispatch(setIsLogin(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(loading(false));
          dispatch(setError('system'));
          dispatch(setOauthToken('', ''));
          dispatch(setIsLogin(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '8504') {
          dispatch(loading(false));
          dispatch(setApplicationStatus(''));
          params && params.empty();
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '14084') {
          dispatch(loading(false));
          dispatch(setError('contact-cs'));
          dispatch(setOauthToken('', ''));
          return;
        }

        dispatch(loading(false));
        dispatch(setError('system'));
        dispatch(setOauthToken('', ''));
        dispatch(setIsLogin(false));

        sendEvent({
          category: 'Unexpected Error Application Status',
          action: getJsonStringify(err.response),
          label: auth.phoneNo
        });
        sendException({
          desc: getJsonStringify(err.response),
          fatal: true
        });
      });
  };
};

export const getMaintenance = (auth, params) => {
  return dispatch => {
    params?.loading && dispatch(loading(true));

    const url = MAINTENANCE;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        if (res.data.data) {
          const resData = res.data.data;

          const findProperty = property => {
            return resData.find(data => data.property === property);
          };

          const dataBlockScreen = findProperty('BLOCKSCREEN');
          const dataFlagBoxSheet = findProperty('FLAGBOXSHEET');
          const dataFlagBox = findProperty('FLAGBOX');
          let flagBoxs = [];

          //set default
          dispatch(setBlockScreen({}));
          dispatch(setBottomSheet({}));
          dispatch(setFlagBoxs([]));

          if (dataBlockScreen?.activated) {
            dispatch(
              setBlockScreen({
                activated: dataBlockScreen.activated,
                tittleBlockScreen: dataBlockScreen.value.tittle,
                descriptionBlockScreen: dataBlockScreen.value.description
              })
            );
            dispatch(loading(false));
            params && params.success && params.success();
            return;
          }

          if (dataFlagBoxSheet?.activated) {
            dispatch(
              setBottomSheet({
                activatedOn: dataFlagBoxSheet.activatedOn,
                tittleBottomSheet: dataFlagBoxSheet.value.tittleBottomSheet,
                descriptionBottomSheet:
                  dataFlagBoxSheet.value.descriptionBottomSheet
              })
            );

            flagBoxs.push({
              activatedOn: dataFlagBoxSheet.activatedOn,
              titleFlagBox: dataFlagBoxSheet.value.tittle,
              descriptionFlagBox: dataFlagBoxSheet.value.description,
              typeFlagBox: dataFlagBoxSheet.type,
              property: dataFlagBoxSheet.property
            });
          }

          if (dataFlagBox?.activated) {
            flagBoxs.push({
              activatedOn: dataFlagBox.activatedOn,
              titleFlagBox: dataFlagBox.value.tittle,
              descriptionFlagBox: dataFlagBox.value.description,
              typeFlagBox: dataFlagBox.type,
              property: dataFlagBox.property
            });
          }

          if (flagBoxs.length > 0) {
            if (flagBoxs.length > 1) {
              flagBoxs.sort((a, b) => {
                if (a.typeFlagBox > b.typeFlagBox || a.property > b.property)
                  return -1;
                else return 1;
              });
            }
            dispatch(setFlagBoxs(flagBoxs));
          }
          params &&
            params.checkFlagBoxMaintenance &&
            params.checkFlagBoxMaintenance(dataFlagBoxSheet?.activatedOn || []);
        }
        params?.loading && dispatch(loading(false));
        params && params.next && params.next();
      })
      .catch(err => {
        dispatch(loading(false));
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      });
  };
};

const rehydratePersonInfo = (applicantDetails, maritalStatusReference) => {
  const validateAddress = type => {
    const addressCondition = [
      applicantDetails?.provinceDetails &&
        !isObjectEmpty(applicantDetails?.provinceDetails),
      applicantDetails?.city && !isObjectEmpty(applicantDetails?.city),
      applicantDetails?.addressLine1,
      applicantDetails?.addressLine2,
      applicantDetails?.addressLine3,
      applicantDetails?.addressLine4 &&
        !isObjectEmpty(applicantDetails?.addressLine4),
      applicantDetails?.addressLine5 &&
        !isObjectEmpty(applicantDetails?.addressLine5),
      applicantDetails?.isAddressSameAsKTP
    ];

    let address = '';

    const getAddressByType = () => {
      switch (type) {
        case 'provinsi':
          return applicantDetails?.provinceDetails;
        case 'kota':
          return applicantDetails?.city;
        case 'fullAddress':
          return applicantDetails?.addressLine1;
        case 'rt':
          return applicantDetails?.addressLine2;
        case 'rw':
          return applicantDetails?.addressLine3;
        case 'kecamatan':
          return applicantDetails?.addressLine4;
        case 'desa':
          return applicantDetails?.addressLine5;
        case 'haveSameAddress':
          return applicantDetails?.isAddressSameAsKTP;
        default:
          break;
      }
    };

    if (addressCondition.every(value => value)) address = getAddressByType();

    return address;
  };

  const validateMaritalStatus = () => {
    if (isObjectEmpty(applicantDetails?.maritalStatusReference))
      return maritalStatusReference;
    else return applicantDetails?.maritalStatusReference;
  };

  return dispatch => {
    dispatch(
      setPersonInfo({
        email: applicantDetails.emailId,
        marriageStatus: validateMaritalStatus(),
        mobileNo: applicantDetails.mobileNo,
        name: applicantDetails.name,
        gender: applicantDetails.gender,
        placeOfBirth: applicantDetails.placeOfBirth,
        dateOfBirth: applicantDetails.dateOfBirth,
        nationalId: applicantDetails.nationalId,
        education: applicantDetails.lastEducation,
        fullAddress: validateAddress('fullAddress') || '',
        rt: validateAddress('rt') || '',
        rw: validateAddress('rw') || '',
        kecamatan: validateAddress('kecamatan') || '',
        desa: validateAddress('desa') || '',
        provinsi: validateAddress('provinsi') || '',
        kota: validateAddress('kota') || '',
        postalCode: Number(applicantDetails.postalCode),
        domisiliStatus: applicantDetails.homeOwnershipStatus,
        homeOwnershipDuration: applicantDetails.homeOwnershipDuration,
        religion: applicantDetails.religion,
        haveSameAddress: validateAddress('haveSameAddress') || false,
        haveHousePhone: applicantDetails.homePhoneNumber ? true : false,
        phone: applicantDetails.homePhoneNumber,
        stayPeriod: applicantDetails.stayPeriod
      })
    );
  };
};

const rehydrateKtpAddressDetails = ktpAddressDetails => {
  return dispatch => {
    dispatch(
      setKtpAddressDetails({
        address: ktpAddressDetails.address || '',
        address2: ktpAddressDetails.address2 || '',
        address3: ktpAddressDetails.address3 || '',
        address4: ktpAddressDetails.address4 || '',
        agama: ktpAddressDetails.agama || '',
        bankCode: ktpAddressDetails.bankCode || '',
        birthDate: ktpAddressDetails.birthDate || '',
        cifNo: ktpAddressDetails.cifNo || '',
        fieldOfBusiness: ktpAddressDetails.businessFieldsCode || '',
        fieldOfWork: ktpAddressDetails.workingCode || '',
        idNo: ktpAddressDetails.idNo || '',
        idType: ktpAddressDetails.idType || '',
        nama: ktpAddressDetails.nama || '',
        placeOfBirth: ktpAddressDetails.placeOfBirth || '',
        province: ktpAddressDetails.province || '',
        sex: ktpAddressDetails.sex || '',
        zipCode: ktpAddressDetails.zipCode || '',
        nameWithoutTitle: ktpAddressDetails.nameWithoutTitle || ''
      })
    );
  };
};

/**
 *
 * @param {*} payrollDetails
 * @param {Object} accountRayaInformation allowed empty
 * @returns
 */
export const rehydrateOccupationInfo = (
  payrollDetails = {},
  accountRayaInformation = {}
) => {
  const validateFieldOfBussinessOccupationInfo = () => {
    if (payrollDetails.businessFieldsCode) {
      if (payrollDetails.businessFieldsCode.length > 4) return '';
      else return payrollDetails.businessFieldsCode;
    } else if (payrollDetails.fieldOfBusiness) {
      return payrollDetails.fieldOfBusiness;
    } else return '';
  };

  const validateFieldOfSubBussinessOccupationInfo = () => {
    if (
      payrollDetails.subBusinessFieldsCode &&
      payrollDetails.subBusinessFieldsDesc
    ) {
      if (payrollDetails.businessFieldsCode.length === 4)
        return payrollDetails.subBusinessFieldsCode;
      else return '';
    } else if (payrollDetails.fieldOfSubBusiness) {
      return payrollDetails.fieldOfSubBusiness;
    } else return '';
  };

  const validateCompanyAddress = () => {
    if (payrollDetails.workAddress) {
      return payrollDetails.workAddress.substring(0, 255);
    } else if (payrollDetails.companyAddress) {
      return payrollDetails.companyAddress.substring(0, 255);
    } else return '';
  };

  const validateCompanyName = () => {
    if (payrollDetails.companyName !== COMPANY_NAME_ALTERNATIVE)
      return payrollDetails.companyName.length > 50
        ? payrollDetails.companyName.substring(0, 50)
        : payrollDetails.companyName;
    else if (payrollDetails.companyName2)
      return payrollDetails.companyName2.length > 50
        ? payrollDetails.companyName2.substring(0, 50)
        : payrollDetails.companyName2;
    else return payrollDetails.companyName;
  };

  const geRehydrateDataOpeningSaving = id => {
    switch (id) {
      case 'fileNpwp':
        if (payrollDetails.fileNpwp) return payrollDetails.fileNpwp;
        else if (accountRayaInformation?.fileNpwp)
          return accountRayaInformation?.fileNpwp;
        else return '';
      case 'jobTitle':
        if (payrollDetails.jobTitle) return payrollDetails.jobTitle;
        else if (accountRayaInformation?.jabatanPekerjaan)
          return accountRayaInformation?.jabatanPekerjaan.cmCode;
        else return '';
      case 'jobTitleDesc':
        if (payrollDetails.jobTitleDesc) return payrollDetails.jobTitleDesc;
        else if (accountRayaInformation?.jabatanPekerjaan)
          return accountRayaInformation?.jabatanPekerjaan.codeDescription;
        else return '';
      case 'provinceOfWork':
        if (payrollDetails.provinceOfWork) return payrollDetails.provinceOfWork;
        else if (
          payrollDetails.companyState &&
          !isObjectEmpty(payrollDetails.companyState)
        )
          return payrollDetails.companyState.cmCode;
        else if (accountRayaInformation?.provinsiTempatBekerja)
          return accountRayaInformation?.provinsiTempatBekerja.cmCode;
        else return '';
      case 'provinceOfWorkDesc':
        if (payrollDetails.provinceOfWorkDesc)
          return payrollDetails.provinceOfWorkDesc;
        else if (
          payrollDetails.companyState &&
          !isObjectEmpty(payrollDetails.companyState)
        )
          return payrollDetails.companyState?.codeDescription;
        else if (accountRayaInformation?.provinsiTempatBekerja)
          return accountRayaInformation?.provinsiTempatBekerja.codeDescription;
        else return '';
      case 'cityOfWork':
        if (payrollDetails.cityOfWork) return payrollDetails.cityOfWork;
        else if (
          payrollDetails.companyCity &&
          !isObjectEmpty(payrollDetails.companyCity)
        )
          return payrollDetails.companyCity?.cmCode;
        else if (accountRayaInformation?.kotaTempatBekerja)
          return accountRayaInformation?.kotaTempatBekerja.cmCode;
        else return '';
      case 'cityOfWorkDesc':
        if (payrollDetails.cityOfWorkDesc) return payrollDetails.cityOfWorkDesc;
        else if (
          payrollDetails.companyCity &&
          !isObjectEmpty(payrollDetails.companyCity)
        )
          return payrollDetails.companyCity?.codeDescription;
        else if (accountRayaInformation?.kotaTempatBekerja)
          return accountRayaInformation?.kotaTempatBekerja.codeDescription;
        else return '';
      case 'districtOfWork':
        if (payrollDetails.districtOfWork) return payrollDetails.districtOfWork;
        else if (
          payrollDetails.companyDistrict &&
          !isObjectEmpty(payrollDetails.companyDistrict)
        )
          return payrollDetails.companyDistrict?.cmCode;
        else if (accountRayaInformation?.kecamatanTempatBekerja)
          return accountRayaInformation?.kecamatanTempatBekerja.cmCode;
        else return '';
      case 'districtOfWorkDesc':
        if (payrollDetails.districtOfWorkDesc)
          return payrollDetails.districtOfWorkDesc;
        else if (
          payrollDetails.companyDistrict &&
          !isObjectEmpty(payrollDetails.companyDistrict)
        )
          return payrollDetails.companyDistrict?.codeDescription;
        else if (accountRayaInformation?.kecamatanTempatBekerja)
          return accountRayaInformation?.kecamatanTempatBekerja.codeDescription;
        else return '';
      case 'wardOfWork':
        if (payrollDetails.wardOfWork) return payrollDetails.wardOfWork;
        else if (
          payrollDetails.companySubDistrict &&
          !isObjectEmpty(payrollDetails.companySubDistrict)
        )
          return payrollDetails.companySubDistrict?.cmCode;
        else if (accountRayaInformation?.kelurahanTempatBekerja)
          return accountRayaInformation?.kelurahanTempatBekerja.cmCode;
        else return '';
      case 'wardOfWorkDesc':
        if (payrollDetails.wardOfWorkDesc) return payrollDetails.wardOfWorkDesc;
        else if (
          payrollDetails.companySubDistrict &&
          !isObjectEmpty(payrollDetails.companySubDistrict)
        )
          return payrollDetails.companySubDistrict?.codeDescription;
        else if (accountRayaInformation?.kelurahanTempatBekerja)
          return accountRayaInformation?.kelurahanTempatBekerja.codeDescription;
        else return '';
      case 'workPhoneNumber':
        if (payrollDetails.workPhoneNumber)
          return payrollDetails.workPhoneNumber;
        else if (accountRayaInformation?.noTlpTempatBekerja)
          return accountRayaInformation?.noTlpTempatBekerja;
        else return '';
      case 'dailyNormalTransactions':
        if (payrollDetails.dailyNormalTransactions)
          return payrollDetails.dailyNormalTransactions;
        else if (accountRayaInformation?.transaksiNormalHarian)
          return accountRayaInformation?.transaksiNormalHarian.cmCode;
        else return '';
      case 'dailyNormalTransactionsDesc':
        if (payrollDetails.dailyNormalTransactionsDesc)
          return payrollDetails.dailyNormalTransactionsDesc;
        else if (accountRayaInformation?.transaksiNormalHarian)
          return accountRayaInformation?.transaksiNormalHarian.codeDescription;
        else return '';
      case 'watch':
        if (
          payrollDetails.npwp ||
          (accountRayaInformation?.npwp && accountRayaInformation.fileNpwp)
        )
          return 'Y';
        else if (
          payrollDetails?.npwp &&
          accountRayaInformation?.npwp &&
          !accountRayaInformation.fileNpwp
        )
          return '';
        else return 'N';
      default:
        break;
    }
  };

  const getRehydrateDataWhitelist = (getState, id) => {
    const whitelistReference = getState().loanApply?.whitelistReferenceVO;
    const verifyPayrollDataWhitelist =
      getState().loanApply?.verifyPayrollData?.whitelistReferenceVO;

    const statusPekerjaWl =
      whitelistReference?.statusPekerjaWl &&
      (whitelistReference?.statusPekerjaWl?.toUpperCase() === 'TETAP' ||
        whitelistReference?.statusPekerjaWl?.toUpperCase() === 'KONTRAK')
        ? whitelistReference?.statusPekerjaWl
        : verifyPayrollDataWhitelist?.statusPekerjaWl.toUpperCase() ===
            'TETAP' ||
          verifyPayrollDataWhitelist?.statusPekerjaWl.toUpperCase() ===
            'KONTRAK'
        ? verifyPayrollDataWhitelist?.statusPekerjaWl
        : '';
    const employeeStatus =
      payrollDetails?.employeeStatus &&
      (payrollDetails?.employeeStatus.toUpperCase() === 'TETAP' ||
        payrollDetails?.employeeStatus.toUpperCase() === 'KONTRAK')
        ? payrollDetails?.employeeStatus
        : '';
    const employeeStatus2nd =
      payrollDetails?.employeeStatus2nd &&
      (payrollDetails?.employeeStatus2nd.toUpperCase() === 'TETAP' ||
        payrollDetails?.employeeStatus2nd.toUpperCase() === 'KONTRAK')
        ? payrollDetails?.employeeStatus2nd
        : '';
    const startDateWl = whitelistReference?.startDateWl
      ? Moment(whitelistReference?.startDateWl).format(
          OCH_DATE_FORMAT_SUBMISSION
        )
      : verifyPayrollDataWhitelist?.startDateWl
      ? Moment(verifyPayrollDataWhitelist?.startDateWl).format(
          OCH_DATE_FORMAT_SUBMISSION
        )
      : '';
    const empStartDateWL = payrollDetails.empStartDateWL
      ? Moment(payrollDetails.empStartDateWL).format(OCH_DATE_FORMAT_SUBMISSION)
      : '';
    const startDate2nd = payrollDetails.startDate2nd
      ? Moment(payrollDetails.startDate2nd).format(OCH_DATE_FORMAT_SUBMISSION)
      : '';
    const empEndDateWL = payrollDetails.empEndDateWL
      ? Moment(payrollDetails.empEndDateWL).format(OCH_DATE_FORMAT_SUBMISSION)
      : '';
    const endDate2nd = payrollDetails.endDate2nd
      ? Moment(payrollDetails.endDate2nd).format(OCH_DATE_FORMAT_SUBMISSION)
      : '';
    const isSecondApply = getState().loanApply.isSecondApply === 'Y';
    const birthDate =
      getState().loanApply.silverLakeCIF.birthDate ||
      getState().loanApply.verifyPayrollData?.silverLakeCIFInquiryReferenceVO
        ?.birthDate ||
      getState().loanApply.applicationData?.ktpAddressDetails?.birthDate ||
      getState().loanApply.applicationData?.payrollDetails?.birthDateWL;
    const empEndDatePermanent = Moment(new Date(birthDate))
      .startOf('day')
      .add(RETIRED_EMPLOYEE_AGE, 'year')
      .format(OCH_DATE_FORMAT_SUBMISSION);

    switch (id) {
      case 'employeeStatus':
        return isSecondApply
          ? statusPekerjaWl || employeeStatus2nd || ''
          : statusPekerjaWl || employeeStatus || '';
      case 'endWorkingDate':
        if (!statusPekerjaWl && !employeeStatus2nd)
          return isSecondApply ? '' : empEndDateWL || '';
        else if (
          statusPekerjaWl.toUpperCase() === 'TETAP' ||
          employeeStatus2nd.toUpperCase() === 'TETAP'
        )
          return empEndDatePermanent || endDate2nd;
        else if (
          statusPekerjaWl.toUpperCase() === 'KONTRAK' ||
          employeeStatus2nd.toUpperCase() === 'KONTRAK'
        )
          return endDate2nd;
        else return '';
      case 'startWorkingDate':
        return isSecondApply
          ? startDateWl || startDate2nd || ''
          : startDateWl || empStartDateWL || '';
      case 'typeOfWork':
        return statusPekerjaWl.toUpperCase() === 'KONTRAK' ||
          employeeStatus.toUpperCase() === 'KONTRAK'
          ? '001'
          : '';
      default:
        break;
    }
  };

  return (dispatch, getState) => {
    dispatch(
      setOccupationInfo({
        addressWL: payrollDetails.addressWL || '',
        birthDateWL: payrollDetails.birthDateWL || '',
        companyAgency: payrollDetails.companyAgency || '',
        companyAddress: validateCompanyAddress(),
        companyName: validateCompanyName() || '',
        companyName2: validateCompanyName(),
        employeeStatus: getRehydrateDataWhitelist(getState, 'employeeStatus'),
        endWorkingDate: getRehydrateDataWhitelist(getState, 'endWorkingDate'),
        fieldOfBusiness: validateFieldOfBussinessOccupationInfo(),
        fieldOfBusinessDesc:
          payrollDetails.fieldOfBusinessDesc ||
          payrollDetails?.businessFieldsDesc ||
          '',
        fieldOfSubBusiness: validateFieldOfSubBussinessOccupationInfo(),
        fieldOfSubBusinessDesc:
          payrollDetails.fieldOfSubBusinessDesc ||
          payrollDetails?.subBusinessFieldsDesc ||
          '',
        fieldOfWork:
          payrollDetails.fieldOfWork || payrollDetails?.workingCode || '',
        fieldOfWorkDesc:
          payrollDetails.fieldOfWorkDesc || payrollDetails?.workingDesc || '',
        genderWL: payrollDetails.genderWL || '',
        income: payrollDetails.netIncomeWL || 0,
        nameWL: payrollDetails.nameWL || '',
        npwp: payrollDetails.npwp || accountRayaInformation?.npwp || '',
        outcome: payrollDetails.expenditureAmount || 0,
        payDay: new Date(payrollDetails.payrollDate).getDate() || 0,
        payrollAccount: payrollDetails.payrollAccount || '',
        payrollAccountBranch: payrollDetails.payrollAccountBranch || '',
        payrollDate: payrollDetails.payrollDate || '',
        placeOfBirthWL: payrollDetails.placeOfBirthWL || '',
        referralCode: payrollDetails.referralCode || '',
        savingAccOwnshpSts: payrollDetails.savingAccOwnshpSts || '',
        savingAmount: payrollDetails.savingAmount || 0,
        startWorkingDate: getRehydrateDataWhitelist(
          getState,
          'startWorkingDate'
        ),
        typeOfWork: getRehydrateDataWhitelist(getState, 'typeOfWork'),
        fileNpwp: geRehydrateDataOpeningSaving('fileNpwp'),
        jobTitle: geRehydrateDataOpeningSaving('jobTitle'),
        jobTitleDesc: geRehydrateDataOpeningSaving('jobTitleDesc'),
        provinceOfWork:
          geRehydrateDataOpeningSaving('provinceOfWork') ||
          payrollDetails.provinceOfWork ||
          '',
        provinceOfWorkDesc: geRehydrateDataOpeningSaving('provinceOfWorkDesc'),
        cityOfWork: geRehydrateDataOpeningSaving('cityOfWork'),
        cityOfWorkDesc: geRehydrateDataOpeningSaving('cityOfWorkDesc'),
        districtOfWork: geRehydrateDataOpeningSaving('districtOfWork'),
        districtOfWorkDesc: geRehydrateDataOpeningSaving('districtOfWorkDesc'),
        wardOfWork: geRehydrateDataOpeningSaving('wardOfWork'),
        wardOfWorkDesc: geRehydrateDataOpeningSaving('wardOfWorkDesc'),
        workPhoneNumber: geRehydrateDataOpeningSaving('workPhoneNumber'),
        dailyNormalTransactions: geRehydrateDataOpeningSaving(
          'dailyNormalTransactions'
        ),
        dailyNormalTransactionsDesc: geRehydrateDataOpeningSaving(
          'dailyNormalTransactionsDesc'
        ),
        watch: geRehydrateDataOpeningSaving('watch')
      })
    );
  };
};

const rehydrateFamilyInfo = contactInformation => {
  return dispatch => {
    dispatch(
      setFamilyInfo({
        emergencyNumber: String(contactInformation.contactNumber || ''),
        marriageStatus: contactInformation.maritalStatusReference,
        mothersMaidenName: contactInformation.motherName,
        numberOfDependents: contactInformation.noOfDependent,
        relationshipWithRelatives: contactInformation.relationshipReference,
        relativesName: contactInformation.contactName,
        contactAddress: contactInformation.emergencyContactAddress || '',
        spouseIdNo:
          contactInformation.spouseId !== '0'
            ? contactInformation.spouseId
            : contactInformation.partnerNikId
      })
    );
  };
};

export const fetchDocRiplay = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));

    const url = STANDARD_TEXT + param.docType;

    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setDocRiplay(res.data.data));
        param && param.action && param.action();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const fetchApplicationData = (auth, param) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    const url =
      USERS +
      '/' +
      'custom/applications/' +
      (auth.prevAppId ? auth.prevAppId : auth.applicationId);
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setApplicationdata(res.data));

        const validateRehydrateOccupationInfo = () => {
          if (
            !isObjectEmpty(res.data.accountRayaInformation) &&
            res.data.accountRayaInformation
          ) {
            if (res.data.accountRayaInformation.fileNpwp) {
              const npwpImg = res.data.accountRayaInformation.fileNpwp?.replace(
                /(?:\\[rn]|[\r\n]+)+/g,
                ''
              );

              dispatch(
                setNpwpImg(String.raw`data:image/jpeg;base64,${npwpImg}`)
              );
            }

            dispatch(
              rehydrateOccupationInfo(
                res.data.payrollDetails,
                res.data.accountRayaInformation
              )
            );
            dispatch(
              setAccountRayaInformation(res.data.accountRayaInformation)
            );
          } else dispatch(rehydrateOccupationInfo(res.data.payrollDetails));
        };

        if (res.data.documentDetails) {
          const ektpImg = res.data.documentDetails.file.replace(
            /(?:\\[rn]|[\r\n]+)+/g,
            ''
          );
          dispatch(setEktpImg(String.raw`data:image/jpeg;base64,${ektpImg}`));
          dispatch(setDocumentDetails(res.data.documentDetails));
          dispatch(setEktp(res.data.documentDetails.docKey));
        }

        if (res.data.applicantDetails) {
          dispatch(rehydratePersonInfo(res.data.applicantDetails, {}));
        }

        if (res.data.respPayrollDetails) {
          dispatch(setRespPayrollDetails(res.data.respPayrollDetails));
          dispatch(setCustomerName(res.data.respPayrollDetails.nameWL));
          dispatch(
            setWhitelistAddress(
              res.data.respPayrollDetails.addressWL,
              res.data.applicantDetails
            )
          );
        }

        if (res.data.ktpAddressDetails) {
          dispatch(setKtpAddressDetails(res.data.ktpAddressDetails));
        }

        if (res.data.payrollDetails) {
          dispatch(setCustomerName(res.data.payrollDetails.nameWL));
          dispatch(
            setWhitelistAddress(
              res.data.payrollDetails.addressWL,
              res.data.applicantDetails
            )
          );
        }

        if (res.data.applicationDetails) {
          dispatch(
            setLoanAmount(res.data.applicationDetails.requestedLoanAmount)
          );
          dispatch(setTenure(res.data.applicationDetails.requestedTenor));
          dispatch(setApplicationDetails(res.data.applicationDetails));
          dispatch(setBankCode(res.data.applicationDetails.bankCode));
          if (
            res.data.applicationDetails.applicationStatus.cmCode === 'PER_SAVED'
          ) {
            dispatch(rehydratePersonInfo(res.data.applicantDetails, {}));
            res.data.ktpAddressDetails &&
              dispatch(rehydrateKtpAddressDetails(res.data.ktpAddressDetails));
          }

          if (
            res.data.applicationDetails.applicationStatus.cmCode === 'PAY_SAVED'
          ) {
            dispatch(rehydratePersonInfo(res.data.applicantDetails, {}));
            res.data.ktpAddressDetails &&
              dispatch(rehydrateKtpAddressDetails(res.data.ktpAddressDetails));

            validateRehydrateOccupationInfo();
          }

          if (
            res.data.applicationDetails.applicationStatus.cmCode ===
              'CON_SAVED' ||
            res.data.applicationDetails.applicationStatus.cmCode === 'LOAN_PAID'
          ) {
            dispatch(
              rehydratePersonInfo(
                res.data.applicantDetails,
                res.data.contactInformation.maritalStatusReference
              )
            );
            res.data.ktpAddressDetails &&
              dispatch(rehydrateKtpAddressDetails(res.data.ktpAddressDetails));
            res.data.contactInformation &&
              dispatch(rehydrateFamilyInfo(res.data.contactInformation));

            validateRehydrateOccupationInfo();
          }
        }

        if (!param?.loading) dispatch(loading(false));

        const accountInquiryPayload = {
          ktp:
            getState().loanApply.eKTP ||
            getState().loanApply.applicationDetails.ktpNum,
          cardNumber:
            getState().loanApply.camscall.accountNumber ||
            getState().loanApply.occupationInfo.payrollAccount ||
            getState().loanApply.respPayrollDetails.payrollAccount,
          bankCode: getState().loanApply.bankCode,
          token: getState().auth.oauthToken,
          schemeCode: getState().loanApply.appInqRes.schemeCode,
          subCode: getState().auth.subCode
        };

        param?.action(accountInquiryPayload);
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      });
  };
};

export const fetchingOptionSelect = (options, action = undefined) => {
  return async dispatch => {
    let promises = options.map(option => dispatch(fetchOptions(option)));
    let result = await Promise.all(promises).then(() => {
      action && action();
    });
    return result;
  };
};

export const fetchLoanScheme = (auth, param) => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    const partnerId = getState().auth.partnerId;
    const url = `${USERS}/custom/loanNewSchemeCodes?partnerId=${partnerId}`;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        if (res.data.data) {
          dispatch(setLoanSchemes(res.data.data));
          if (res.data.data[0].breakUpDetailsList)
            dispatch(setSubCodeLoanScheme(res.data.data[0].breakUpDetailsList));

          if (
            getState().loanApply?.appInqRes?.schemeCode &&
            getState().loanApply?.appInqRes?.applicationStatus.cmCode !==
              'LOAN_PAID'
          ) {
            const choiceSchemeCode = res.data.data.find(
              item =>
                item.schemeCode === getState().loanApply.appInqRes.schemeCode
            );

            if (choiceSchemeCode) dispatch(setLoanScheme(choiceSchemeCode));
            else
              dispatch(
                setLoanScheme({
                  schemeCode: getState().loanApply.appInqRes.schemeCode
                })
              );
          }

          const loanSchemes = res.data.data;

          const allowedAppStatus = [
            'LOAN_PAID',
            'APP_EXPIRED',
            'APP_CREATED',
            'USR_REJECT',
            'CR_SCORE_REJ',
            'PAYROLL_REJ',
            'LOAN_CREATED'
          ];

          const allowedSchemeCodeFlexi = [
            SCHEME_CODE_PNANG,
            SCHEME_CODE_PNNEW,
            SCHEME_CODE_KASBON,
            SCHEME_CODE_PMI
          ];

          const appInqSchemeCode = getState().loanApply.appInqRes.schemeCode;

          let loanNewSchemeCode = loanSchemes.map(a => a.schemeCode);

          const appInqSubCode = getState().loanApply.appInqRes.subCode || '';
          const authSubCode = getState().auth.subCode || '';

          const webviewSaving = [
            // {
            //   schemeCode: SCHEME_CODE_PNANG,
            //   subCode: ''
            // },
            {
              schemeCode: SCHEME_CODE_PNANG,
              subCode: 'RAYA'
            },
            {
              schemeCode: SCHEME_CODE_PNNEW,
              subCode: ''
            },
            {
              schemeCode: SCHEME_CODE_PMI,
              subCode: ''
            }
          ];

          const appInqValidation = webviewSaving.some(
            item =>
              item.schemeCode === appInqSchemeCode &&
              item.subCode === appInqSubCode
          );

          const existingValidation = webviewSaving.some(
            item =>
              loanNewSchemeCode.includes(item.schemeCode) &&
              item.subCode === authSubCode
          );

          if (appInqSchemeCode) {
            if (
              (!(appInqValidation === existingValidation) &&
                !allowedAppStatus.includes(
                  getState().loanApply.appInqRes.applicationStatus.cmCode
                )) ||
              !allowedSchemeCodeFlexi.includes(appInqSchemeCode)
            ) {
              param && param.isDifferentScheme();
              dispatch(loading(false));
              return;
            }
          }

          if (
            appInqSchemeCode === SCHEME_CODE_KASBON ||
            loanNewSchemeCode.includes(SCHEME_CODE_KASBON)
          ) {
            dispatch(
              fetchCustCategory(auth, {
                action: () => param && param.next && param.next()
              })
            );
          } else {
            dispatch(loading(false));
            param && param.next && param.next();
          }
        } else {
          dispatch(loading(false));
          dispatch(setError('system'));
        }
      })
      .catch(err => {
        dispatch(loading(false));
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          return;
        } else {
          dispatch(setError('system'));
          return;
        }
      })
      .finally(() => {
        if (
          auth.appStatus !== 'LOAN_CREATED' ||
          getState().loanApply.applicationStatus !== 'LOAN_CREATED'
        ) {
          if (
            partnerId === PARTNER_ID_GENERAL &&
            getState().loanApply?.subCodeLoanScheme
          ) {
            const newArrSubCode = getState().loanApply?.subCodeLoanScheme?.map(
              e => e.subCode.includes(getState().auth.subCode)
            );

            if (
              !getState().auth.subCode ||
              newArrSubCode.every(item => item === false)
            ) {
              dispatch(setError('system'));
            }
          }
        }
      });
  };
};

export const fetchLoanProduct = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/loanproducts';
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        res.data.data && dispatch(setLoanProduct(res.data.data));
        param && param.action && param.action();
      })
      .catch(err => {
        dispatch(loading(false));

        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      })
      .finally(() => {
        param?.loading && dispatch(loading(false));
      });
  };
};

export const fetchCustCategory = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERSV2 + '/' + 'custom/applications/custcategory';
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        res.data.data && dispatch(setCustCategory(res.data.data));
        param?.action();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const fetchBankCodes = (token, action) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/bankcodes/002';
    const header = {
      headers: {
        Authorization: token
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        if (res.data.data) {
          dispatch(
            setBankOptions(
              [{ value: '', label: '' }].concat(
                res.data.data[0].bankDetails.map(x => {
                  return {
                    value: x.bankCode,
                    label: x.bankName
                  };
                })
              )
            )
          );
          action && action();
          return;
        }
      })
      .catch(err => {
        dispatch(loading(false));

        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      });
  };
};

export const atmInquiry = (data, action) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/atminquiry';
    const body = {
      ktp: data.ktp,
      cardNumber: data.cardNumber,
      expiryDate: data.expiryDate,
      bankCode: data.bankCode,
      productCode: '002'
    };
    const header = {
      headers: {
        Authorization: data.token
      }
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        const responseCode = res.data.code;

        if (responseCode === 211047 || responseCode === 211048) {
          dispatch(setError('contact-cs'));
          return;
        }

        if (responseCode === 211042) {
          dispatch(setError('expiry_date_mismatch'));
          return;
        }

        if (responseCode === 211043) {
          dispatch(setError('card_is_not_active'));
          return;
        }

        if (responseCode === 211049) {
          dispatch(setError('account_is_not_open'));
          return;
        }

        if (responseCode === 211050) {
          dispatch(setError('incorrect_ktp'));
          return;
        }

        if (responseCode === 211051) {
          dispatch(setError('entered_ktp_number_is_not_correct'));
          return;
        }

        if (responseCode === 211091) {
          dispatch(setError('user_unabled_to_do_atm_inquiry_for_15_minutes'));
          return;
        }

        if (responseCode === 211097) {
          dispatch(setError('nik_is_already_registered'));
          return;
        }

        if (responseCode === 110882) {
          dispatch(setError('incorrect_ktp'));
          return;
        }

        if (res.data.data) {
          dispatch(setAtmInqRes(res.data.data));
          dispatch(
            setCustomerName(
              res.data.data[0].silverLakeCIFInquiryReferenceVO.nama
            )
          );
          dispatch(
            setWhitelistAddress(
              res.data.data[0].silverLakeCIFInquiryReferenceVO.address,
              null
            )
          );
        }
        action(res.data.data[0]);
      })
      .catch(err => {
        sendEvent({
          category: 'Error ATM Inquiry Verification',
          action: JSON.stringify(err.response),
          label: data.phoneNo
        });

        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (err?.response?.status === 422) {
          dispatch(setError('miss_match'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        dispatch(loading(false));
        dispatch(setError('system'));
      })
      .finally(() => dispatch(loading(false)));
  };
};

export const verifyPayroll = (data, action, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + VERIFY_PAYROLL;
    const header = {
      headers: {
        Authorization: data.token
      }
    };

    const body = {
      schemeCode: data.schemeCode,
      subCode: data.subCode
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        if (res.data.data) {
          dispatch(setVerifyPayroll(res.data.data));
          /**
           * get data rayanet
           */
          if (res.data.data.rayanetInquiryReferenceVO)
            dispatch(setRayanetInqRes(res.data.data.rayanetInquiryReferenceVO));

          /**
           * get data kasbon
           */
          if (res.data.data.kasbonReferenceVO) {
            dispatch(setKasbonInqRes(res.data.data.kasbonReferenceVO));

            if (res.data.data.kasbonReferenceVO.isEligibleKasbon === 'N') {
              if (res.data.data.kasbonReferenceVO?.eligibilityDate)
                dispatch(setError('not_eligible_date_kasbon'));
              else dispatch(setError('not_eligible_min_plafond_kasbon'));

              dispatch(loading(false));
              return;
            }
          }

          if (res.data.data.whitelistReferenceVO) {
            dispatch(
              setWhitelistReferenceVO(res.data.data.whitelistReferenceVO)
            );
          }
        }

        action && action(res.data.data);

        if (param?.loading) dispatch(loading(false));
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          dispatch(loading(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '211049') {
          dispatch(setError('account_is_not_open'));
          dispatch(loading(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          dispatch(loading(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '211047') {
          dispatch(setError('contact-cs'));
          dispatch(loading(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '211053') {
          dispatch(setError('error_minimum_balance'));
          dispatch(loading(false));
          return;
        }

        dispatch(loading(false));
        dispatch(setError('system'));
      });
  };
};

/**
 *
 * @param {Object} auth
 * @param {Object} body
 * @param {Function} action
 * @param {Function} history
 * @returns
 *
 * NEW FUNCTION VERIFICATION PAYROLL
 */
export const payrollVerification = (auth, body, action, history) => {
  return dispatch => {
    dispatch(setApplicationDetails(body));
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/applications';
    const header = {
      headers: {
        Authorization: auth.token
      }
    };
    axiosGolang
      .post(url, body, header)
      .then(res => {
        const resCode = res?.data?.code;
        const status = res?.data?.data?.applicationStatus?.cmCode;
        dispatch(setApplicationId(res?.data?.data.applicationId));
        dispatch(setApplicationStatus(status));
        dispatch(setIsExistWhitelist(res.data.data.applicationStatus.isExist));
        dispatch(
          setIsCustomerConsent(
            body?.applicationDetails?.isCustomerConsentAgreement
          )
        );

        if (resCode === 211091) {
          dispatch(setError('contact-cs'));
          return;
        }

        if (resCode === 211051) {
          dispatch(setError('contact-cs'));
          return;
        }

        if (status === 'PAYROLL_REJ') {
          localStorage.setItem(
            'dataVeryRek',
            JSON.stringify({
              bankCode: body.applicationDetails.bankCode,
              bankNumber: body.payrollSessionDetails.payrollAccount,
              ektp: body.applicationDetails.ktpNum
            })
          );
          history && history.push('/payroll-reject');
          return;
        }

        if (status === 'PAYROLL_APP') {
          history && history.push('/form-fill');
        }

        action && action();
      })
      .catch(err => {
        if (err?.response?.status === 422) {
          dispatch(setError('system'));
          dispatch(setApplicationStatus('APP_CREATED'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '211101') {
          history && history.push('/payroll-reject');
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '1014') {
          dispatch(setError('system'));
          dispatch(setApplicationStatus('APP_CREATED'));
          dispatch(applicationInquiry(auth));
          return;
        }

        sendEvent({
          category: 'Error Payroll Verification',
          action: getJsonStringify(err.response),
          label: auth.phoneNo
        });

        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }
        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const submitUserDetails = (auth, param) => {
  return dispatch => {
    const url =
      USERS +
      '/' +
      (localStorage.getItem('phonePrincipal') || auth.phoneNo) +
      '/custom/' +
      COMPANY_INFO;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };
    const body = {
      companyName: param.companyName,
      companyAddress: param.companyAddress
    };

    axios
      .post(url, body, header)
      .then(() => {
        param && param.action();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      });
  };
};

export const resubmitKTP = (auth, body, action) => {
  return dispatch => {
    const url = USERS + '/custom/applications/' + auth.applicationId;
    const config = {
      signal: controller.signal,
      headers: {
        Authorization: auth.token
      }
    };
    dispatch(setError(''));
    axiosGolang
      .put(url, body, config)
      .then(res => {
        if (res.data.data) {
          dispatch(setRespKtpSubmit(res.data.data));
          dispatch(setApplicationId(res.data.data.applicationId));
          dispatch(
            setApplicationStatus(res.data.data.applicationStatus.cmCode)
          );
          action.finish && action.finish();
        }
      })
      .catch(err => {
        dispatch(setHomePath('/continue-apply'));
        if (err.code === 'ERR_CANCELED') {
          return;
        }
        if (err.code === 'ERR_NETWORK') {
          dispatch(setError('connection'));
          return;
        }
        if (err.response && err.response.status === 400) {
          if (getChannelContextCode(err, 'channel-context') === null) {
            sendEvent({
              category: 'Unexpected Error KTP Upload',
              action: getJsonStringify(err.response),
              label: auth.phoneNo
            });
            sendException({
              desc: getJsonStringify(err.response),
              fatal: true
            });
            dispatch(setError('contact-cs'));
            dispatch(setEktpImg(''));
            return;
          }
          // KTP 15 Mins
          if (getChannelContextCode(err, 'channel-context') === '211091') {
            dispatch(setError('ktp_failed_15mins'));
            return;
          }
          if (getChannelContextCode(err, 'channel-context') === '211051') {
            dispatch(setError('incorrect_ktp'));
            return;
          }
        } else {
          dispatch(setError('contact-cs'));
          sendEvent({
            category: 'Unexpected Error KTP Upload',
            action: getJsonStringify(err.response),
            label: auth.phoneNo
          });
          sendException({
            desc: getJsonStringify(err.response),
            fatal: true
          });
          return;
        }
      })
      .finally(() => {
        dispatch(loading(false));
        action.show && action.show();
      });
  };
};

export const ktpSubmitForm = (auth, body, action) => {
  return dispatch => {
    dispatch(loading(true));

    const url = USERS + '/' + 'custom/applications/' + auth.applicationId;
    const header = {
      headers: {
        Authorization: auth.token
      }
    };

    axiosGolang
      .put(url, body, header)
      .then(res => {
        if (res.data.data) {
          dispatch(setKtpSubmitFormRes(res.data.data));
        }
        action && action.action();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      });
  };
};

export const submitForm = (param, body, action) => {
  return dispatch => {
    dispatch(loading(true));

    const url = USERS + '/' + 'custom/applications/' + param.applicationId;
    const header = {
      headers: {
        Authorization: param.token
      }
    };
    axiosGolang
      .put(url, body, header)
      .then(res => {
        if (res.status.toString().slice(0, 1) === '2') {
          param.familyInfo && dispatch(setFamilyInfo(param.familyInfo));

          if (
            param.whitelistAddress &&
            param.whitelistAddress.fullAddress &&
            body.applicantInformation
          ) {
            setWhitelistAddress(
              param.whitelistAddress.fullAddress,
              body.applicantInformation
            );
          }

          if (
            param.mode === 'add' &&
            !param.personInfo &&
            !param.occupationInfo &&
            !param.familyInfo
          ) {
            action.push('/form-fill');
          }

          if (
            param.mode === 'edit' &&
            !param.occupationInfo &&
            !param.personInfo &&
            !param.familyInfo
          ) {
            action(prev => !prev);
          }

          if (param.mode === 'pause') {
            dispatch(setApplicationId(res.data.data.applicationId));
          }

          if (param.mode === 'submit' && res.data.data) {
            dispatch(setCreditScoringRes(res.data.data));
            dispatch(setApplicationId(res.data.data.applicationId));
            dispatch(
              setTimer({
                minutes: res.data.data.timeLeftForScoreCompletion,
                seconds: 0
              })
            );
            dispatch(setCreditScoreResult(''));
          }

          dispatch(
            setApplicationStatus(res.data.data.applicationStatus.cmCode)
          );

          return true;
        }
        return false;
      })
      .then(ok => {
        if (!ok && !param.occupationInfo) return;
        action && action();
      })
      .catch(err => {
        dispatch(setHomePath('/continue-apply'));

        if (getChannelContextCode(err, 'channel-context') === '211053') {
          dispatch(setError('error_minimum_balance'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '11017') {
          dispatch(setError('same-phonenum-relationship'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '11018') {
          dispatch(setError('same-phonenum-other'));
          dispatch(setIsSameContactOther(true));
          return;
        }

        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const fetchProvinceOptions = callback => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    axiosGolang
      .get(PROVINCE, { headers: { Authorization: getState().auth.VTtoken } })
      .then(res => {
        dispatch(
          setProvinceOptions(
            [{ value: '', label: '' }].concat(
              res.data.map(data => {
                return {
                  value: data.stateCode,
                  label: data.stateDescription
                };
              })
            )
          )
        );

        callback && callback();
      })
      .catch(err => {
        dispatch(setHomePath('/continue-apply'));
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      });
  };
};

export const fetchFieldOfBusinessOptions = callback => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    axiosGolang
      .get(FIELD_OF_BUSINESS, {
        headers: { Authorization: getState().auth.VTtoken }
      })
      .then(res => {
        dispatch(
          setFieldOfBusinessOptions(
            [{ value: '', label: '' }].concat(
              res.data.map(data => {
                return {
                  value: data.cmCode,
                  label: data.codeDescription
                };
              })
            )
          )
        );

        callback && callback();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      });
  };
};

export const fetchFieldOfWorkOptions = callback => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    axiosGolang
      .get(FIELD_OF_WORKS, {
        headers: { Authorization: getState().auth.VTtoken }
      })
      .then(res => {
        dispatch(
          setFieldOfWorkOptions(
            [{ value: '', label: '' }].concat(
              res.data.map(data => {
                return {
                  value: data.cmCode,
                  label: data.codeDescription
                };
              })
            )
          )
        );

        callback && callback();
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      });
  };
};

export const fetchFieldOfJobTitleOptions = callback => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    axiosGolang
      .get(USERS + FIELD_JOB_TITLE, {
        headers: { Authorization: getState().auth.oauthToken }
      })
      .then(res => {
        dispatch(
          setJobTitleOptions(
            [{ value: '', label: '' }].concat(
              res.data.data.listItem.map(data => {
                return {
                  key: data.key,
                  value: data.value
                };
              })
            )
          )
        );

        callback && callback();
      })
      .catch(err => {
        dispatch(setHomePath('/continue-apply'));
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      });
  };
};

export const fetchFieldOfDailyNormalTransactionsOptions = callback => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    axiosGolang
      .get(USERS + FIELD_DAILY_NORMAL_TRANSACTION, {
        headers: { Authorization: getState().auth.oauthToken }
      })
      .then(res => {
        dispatch(
          setDailyNormalTransactionsOptions(
            [{ value: '', label: '' }].concat(
              res.data.data.listItem.map(data => {
                return {
                  key: data.key,
                  value: data.value
                };
              })
            )
          )
        );

        callback && callback();
      })
      .catch(err => {
        dispatch(setHomePath('/continue-apply'));
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      });
  };
};

/**
 * Get list from API to be filled up on UI
 * @param path
 * @param code
 * @param callback after success get list
 * @returns {function(...[*]=)}
 */
export const fetchOptions = (path, code, callback) => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    const url = CODE_REFERENCES + path;
    const header = {
      headers: {
        Authorization: getState().auth.VTtoken
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        if (res?.data) {
          if (path === '/LNPS') {
            dispatch(
              setLoanPurposeOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.cmCode,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          } else if (path === '/EDU') {
            dispatch(
              setEducationOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.cmCode,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          }
          // else if (path === '/CCBK') {
          //   dispatch(
          //     setCreditCardOptions(
          //       [{ value: '', label: '' }].concat(
          //         res.data.map(data => {
          //           return {
          //             value: data.cmCode,
          //             label: data.codeDescription
          //           };
          //         })
          //       )
          //     )
          //   );
          // }
          else if (path === '/RES') {
            dispatch(
              setResidenceOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.cmCode,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          } else if (path === '/CTOW') {
            dispatch(
              setTypeOfWorkOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.cmCode,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          } else if (path === '/LPK') {
            dispatch(
              setCompanyAgencyOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.codeDescription,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          } else if (path === '/EMES') {
            dispatch(
              setEmployeeOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.codeDescription,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          } else if (path === '/CPOA') {
            dispatch(
              setPurposeOfOpeningAccountOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.cmCode,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          } else if (path === '/CSOI') {
            dispatch(
              setSourceOfIncomeOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.cmCode,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          } else if (path === '/CNY') {
            dispatch(
              setCompanyOptions(
                res.data.map(data => {
                  return {
                    value: data.codeDescription,
                    label: data.codeDescription
                  };
                })
              )
            );
          } else if (path === '/MST') {
            dispatch(
              setMarriageOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.cmCode,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          } else if (path === '/DEPN') {
            dispatch(
              setDependentsOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.cmCode,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          } else if (path === '/RLT') {
            dispatch(
              setRelativesOptions(
                [{ value: '', label: '' }].concat(
                  res.data.map(data => {
                    return {
                      value: data.cmCode,
                      label: data.codeDescription
                    };
                  })
                )
              )
            );
          } else if (/\d/.test(path)) {
            if (code === 'kota' || code === 'cityOfWork') {
              dispatch(
                setCityOptions(
                  [{ value: '', label: '' }].concat(
                    res.data.map(data => {
                      return {
                        value: data.cmCode,
                        label: data.codeDescription
                      };
                    })
                  )
                )
              );
            } else if (code === 'kecamatan' || code === 'districtOfWork') {
              dispatch(
                setSubDistrictOptions(
                  [{ value: '', label: '' }].concat(
                    res.data.map(data => {
                      return {
                        value: data.cmCode,
                        label: data.codeDescription
                      };
                    })
                  )
                )
              );
            } else if (code === 'desa' || code === 'wardOfWork') {
              dispatch(
                setVillageOptions(
                  [{ value: '', label: '' }].concat(
                    res.data.map(data => {
                      return {
                        value: data.cmCode,
                        label: data.codeDescription
                      };
                    })
                  )
                )
              );
            } else if (code === '/SUBBDSH') {
              dispatch(
                setFieldOfSubBusinessOptions(
                  [{ codeType: '', value: '', label: '' }].concat(
                    res.data.map(data => {
                      return {
                        codeType: data.codeType,
                        value: data.cmCode,
                        label: data.codeDescription
                      };
                    })
                  )
                )
              );
            }
          }
        } else {
          dispatch(setError('system'));
          dispatch(loading(false));
        }
      })
      .then(() => {
        callback && callback();
      })
      .catch(err => {
        if (path !== '/LNPS') dispatch(setHomePath('/continue-apply'));
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(loading(false));
      });
  };
};

export const submitCreditScoring = (param, body, action) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/applications/' + param.applicationId;
    const header = {
      headers: {
        Authorization: param.token
      }
    };

    axiosGolang
      .put(url, body, header)
      .then(res => {
        dispatch(setCreditScoringRes(res.data.data));
        dispatch(
          setApplicationStatus(res.data.data?.applicationStatus?.cmCode)
        );
        localStorage.setItem('setCSFromFormFill', 1);
        dispatch(
          setTimer({
            minutes: res.data.data.timeLeftForScoreCompletion,
            seconds: 0
          })
        );
        dispatch(setCreditScoreResult(''));
        action && action();
      })
      .catch(err => {
        dispatch(setHomePath('/continue-apply'));
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        // OCR FAILED
        if (getChannelContextCode(err, 'channel-context') === '211051') {
          dispatch(setError('OCR_FAILED'));
          return;
        }

        // PAYROLL ERROR
        if (getChannelContextCode(err, 'channel-context') === '211101') {
          dispatch(setError('PAYROLL_ERROR'));
          return;
        }
        dispatch(setError('system'));
        sendEvent({
          category: 'Unexpected Error Credit Scoring 1st Loan',
          action: getJsonStringify(err.response),
          label: param.phoneNo
        });
        sendException({
          desc: getJsonStringify(err.response),
          fatal: true
        });
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const secondLoanCreditScoring = (param, body, action) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/custom/applications/' + param.applicationId;
    const header = {
      headers: {
        Authorization: param.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        dispatch(setCreditScoringRes(res.data.data));
        localStorage.setItem('setCSFromFormFill', 1);
        dispatch(setApplicationId(res.data.data.applicationId));
        dispatch(setApplicationStatus(res.data.data.applicationStatus.cmCode));
        dispatch(
          setTimer({
            minutes: res.data.data.timeLeftForScoreCompletion,
            seconds: 0
          })
        );
        dispatch(setCreditScoreResult(''));
        action && action();
      })
      .catch(err => {
        dispatch(setHomePath('/continue-apply'));
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        // EMPTY LINE ADDRESS 4
        if (
          getChannelContextCode(err, 'channel-context') === 'LNINQ01' &&
          getChannelContextMsg(err, 'channel-context').includes(
            'applicantInformation.addressline4'
          )
        ) {
          dispatch(setError('empty_address_line4'));
          return;
        }

        // EMPTY FILE NPWP
        if (
          getChannelContextCode(err, 'channel-context') === 'LNINQ01' &&
          getChannelContextMsg(err, 'channel-context').includes(
            'accountInformation.fileNpwp'
          )
        ) {
          dispatch(setError('empty_file_npwp'));
          return;
        }

        // SAVING AMOUNT <= 0
        if (getChannelContextCode(err, 'channel-context') === '211053') {
          dispatch(setError('error_minimum_balance'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '11017') {
          dispatch(setError('same-phonenum-relationship'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '11018') {
          dispatch(setError('same-phonenum-other'));
          dispatch(setIsSameContactOther(true));
          return;
        }

        // OCR FAILED
        if (getChannelContextCode(err, 'channel-context') === '211051') {
          dispatch(setError('OCR_FAILED'));
          return;
        }

        // PAYROLL ERROR
        if (getChannelContextCode(err, 'channel-context') === '211101') {
          dispatch(setError('PAYROLL_ERROR'));
          return;
        }

        if (
          err.code === 'ERR_BAD_REQUEST' ||
          getChannelContextCode(err, 'channel-context') === null
        ) {
          dispatch(setError('system'));
          return;
        }

        dispatch(setError('system'));

        sendEvent({
          category: 'Unexpected Error Credit Scoring 2nd Loan',
          action: getJsonStringify(err.response),
          label: param.phoneNo
        });
        sendException({
          desc: getJsonStringify(err.response),
          fatal: true
        });
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const ktpRejectedToAppExpired = (param, action) => {
  return dispatch => {
    dispatch(loading(true));

    const url =
      USERS + '/custom/applications/' + param.applicationId + '/expired';
    const header = {
      headers: {
        Authorization: param.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .put(url, null, header)
      .then(res => {
        if (res.data.data) {
          dispatch(
            setApplicationStatus(res.data.data.applicationStatus.cmCode)
          );
          dispatch(setApplicationId(res.data.data.applicationId));
          action && action.action();
        }
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
          return;
        }

        dispatch(setError('system'));
      })
      .finally(() => dispatch(loading(false)));
  };
};

export const setApplicationdata = data => {
  return {
    type: actionTypes.RESUME_APPLICATION,
    res: data
  };
};

export const setLoanProduct = product => {
  return {
    type: actionTypes.SET_LOAN_PRODUCT_OPTIONS,
    product: product
  };
};

export const setCustCategory = custCategory => {
  return {
    type: actionTypes.SET_CUST_CATEGORY_OPTIONS,
    custCategory: custCategory
  };
};

export const setLoanAmount = amount => {
  return {
    type: actionTypes.SET_LOAN_AMOUNT,
    amount: amount
  };
};

export const setTenure = tenure => {
  return {
    type: actionTypes.SET_TENURE,
    tenure: tenure
  };
};

export const setLoanPurpose = purpose => {
  return {
    type: actionTypes.SET_LOAN_PURPOSE,
    purpose: purpose
  };
};

export const setAccountNumber = accountNumber => {
  return {
    type: actionTypes.SET_ACCOUNT_NUMBER,
    accountNumber: accountNumber
  };
};

export const registerWhitelist = (data, token, history) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/payroll-upload';
    const body = data;
    const header = {
      headers: {
        Authorization: token
      }
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        const responseCode = res.data.code;
        if (responseCode === '001') {
          dispatch(setError('failed_insert'));
          return;
        }
        if (responseCode === '000') {
          history && history.push('/whitelist-success');
        }
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '1003') {
          dispatch(setError('failed_insert'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        if (err.response && err.response.status === 422) {
          dispatch(setError('miss_match'));
          return;
        }
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const accountInquiry = (data, action) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + '/' + 'custom/accountinquiry';
    const body = {
      ktp: data.ktp,
      acctNo: data.cardNumber,
      bankCode: data.bankCode,
      productCode: '002',
      schemeCode: data.schemeCode,
      subCode: data.subCode
    };
    const header = {
      headers: {
        Authorization: data.token
      }
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        const responseCode = res.data.code;
        if (responseCode) dispatch(loading(false));

        if (
          responseCode === 211047 ||
          responseCode === 211048 ||
          responseCode === 1008
        ) {
          dispatch(setError('contact-cs'));
          return;
        }

        if (responseCode === 211042) {
          dispatch(setError('expiry_date_mismatch'));
          return;
        }

        if (responseCode === 211049) {
          dispatch(setError('account_is_not_open'));
          return;
        }

        if (responseCode === 211050) {
          dispatch(setError('incorrect_ktp'));
          return;
        }

        if (responseCode === 211051) {
          dispatch(setError('entered_ktp_number_is_not_correct'));
          return;
        }

        if (responseCode === 211091) {
          dispatch(setError('user_unabled_to_do_atm_inquiry_for_15_minutes'));
          return;
        }

        if (responseCode === 211097) {
          dispatch(setError('nik_is_already_registered'));
          return;
        }

        if (responseCode === 211052) {
          dispatch(setError('getting_older_young'));
          return;
        }

        if (responseCode === 211053) {
          dispatch(setError('error_minimum_balance'));
          return;
        }

        if (res.data.data) {
          dispatch(setAtmInqRes(res.data.data));
          dispatch(
            setCustomerName(
              res.data.data[0].silverLakeCIFInquiryReferenceVO.nama
            )
          );
          dispatch(
            setWhitelistAddress(
              res.data.data[0].silverLakeCIFInquiryReferenceVO.address,
              null
            )
          );

          /**
           * get data rayanet in account inquiry
           */

          if (res.data.data[0].rayanetInquiryReferenceVO)
            dispatch(
              setRayanetInqRes(res.data.data[0].rayanetInquiryReferenceVO)
            );

          /**
           * get data kasbon in account inquiry
           */
          if (res.data.data[0].kasbonReferenceVO) {
            dispatch(setKasbonInqRes(res.data.data[0].kasbonReferenceVO));

            if (res.data.data[0].kasbonReferenceVO.isEligibleKasbon === 'N') {
              if (res.data.data[0].kasbonReferenceVO?.eligibilityDate)
                dispatch(setError('not_eligible_date_kasbon'));
              else dispatch(setError('not_eligible_min_plafond_kasbon'));

              return;
            }
          }

          /**
           * get data whitelist in account inquiry
           */
          if (res.data.data[0].whitelistReferenceVO) {
            dispatch(
              setWhitelistReferenceVO(res.data.data[0].whitelistReferenceVO)
            );
          }
        }

        action && action?.approve && action?.approve();
        action && action?.payrollverf && action?.payrollverf(res.data.data[0]);
        action && action?.push && action?.push();
      })
      .catch(err => {
        sendEvent({
          category: 'Error ATM Inquiry Verification',
          action: JSON.stringify(err.response),
          label: data.phoneNo
        });

        dispatch(loading(false));

        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (err.response && err.response.status === 422) {
          dispatch(setError('miss_match'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '1026') {
          dispatch(setError('contact-cs'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }
        dispatch(setError('system'));
      })
      .finally(() => dispatch(loading(false)));
  };
};

export const setProductConfig = config => {
  return {
    type: actionTypes.SET_PRODUCT_CONFIG,
    rate: config.rate,
    code: config.code,
    category: config.category,
    subCategory: config.subCategory,
    schemeCode: config.schemeCode,
    installment: config.installment
  };
};

export const setApplicationId = id => {
  return {
    type: actionTypes.SET_APPLICATION_ID,
    id: id
  };
};

export const setApplicationStatus = status => {
  return {
    type: actionTypes.SET_APPLICATION_STATUS,
    status: status
  };
};

export const setNextDate = nextDate => {
  return {
    type: actionTypes.SET_NEXT_DATE,
    nextDate: nextDate
  };
};

export const setResponseCodeReject = responseCodeReject => {
  return {
    type: actionTypes.SET_RESPONSE_CODE_REJECT,
    responseCodeReject: responseCodeReject
  };
};

export const setAppInqRes = res => {
  return {
    type: actionTypes.SET_APP_INQUIRY_RESPONSE,
    res: res
  };
};

export const setApprovalDetails = data => {
  return {
    type: actionTypes.SET_APPROVAL_DETAILS,
    data: data
  };
};

export const setIncompleteApp = data => {
  return {
    type: actionTypes.SET_INCOMPLETE_APP,
    data: data
  };
};

export const setEktp = ektp => {
  return {
    type: actionTypes.SET_EKTP,
    ektp: ektp
  };
};

export const setEktpRaya = eKTPRaya => {
  return {
    type: actionTypes.SET_EKTP_RAYA,
    eKTPRaya: eKTPRaya
  };
};

export const setBankCode = source => {
  return {
    type: actionTypes.SET_BANK_CODE,
    source: source
  };
};

export const setCardNumber = cardNo => {
  return {
    type: actionTypes.SET_CARD_NUMBER,
    cardNumber: cardNo
  };
};

export const setCardExpDate = expDate => {
  return {
    type: actionTypes.SET_CARD_EXPIRED_DATE,
    expDate: expDate
  };
};

export const setAtmInqRes = response => {
  return {
    type: actionTypes.SET_ATM_INQUIRY_RESPONSE,
    response: response
  };
};

export const setCustomerName = custName => {
  return {
    type: actionTypes.SET_CUSTOMER_NAME,
    name: custName
  };
};

export const setDisburseLifetime = disburseLifetime => {
  return {
    type: actionTypes.SET_DISBURSE_LIFETIME,
    disburseLifetime: disburseLifetime
  };
};

export const setSubtractionDisburseLifeTime = () => {
  return {
    type: actionTypes.SET_SUBTRACTION_DISBURSE_LIFETIME
  };
};

export const setLoanPurposeOptions = options => {
  return {
    type: actionTypes.SET_LOAN_PURPOSE_OPTIONS,
    options: options
  };
};

export const setBankOptions = bankCodes => {
  return {
    type: actionTypes.SET_BANK_OPTIONS,
    bankCodes: bankCodes
  };
};

export const setEducationOptions = options => {
  return {
    type: actionTypes.SET_EDUCATION_OPTIONS,
    options: options
  };
};
export const setFieldOfWorkOptions = options => {
  return {
    type: actionTypes.SET_FIELD_OF_WORK_OPTIONS,
    options: options
  };
};
export const setFieldOfBusinessOptions = options => {
  return {
    type: actionTypes.SET_FIELD_OF_BUSINESS_OPTIONS,
    options: options
  };
};

export const setFieldOfSubBusinessOptions = options => {
  return {
    type: actionTypes.SET_FIELD_OF_SUB_BUSINESS_OPTIONS,
    options: options
  };
};

export const setProvinceOptions = options => {
  return {
    type: actionTypes.SET_PROVINCE_OPTIONS,
    options: options
  };
};

export const setCityOptions = options => {
  return {
    type: actionTypes.SET_CITY_OPTIONS,
    options: options
  };
};

export const setSubDistrictOptions = options => {
  return {
    type: actionTypes.SET_SUB_DISTRICT_OPTIONS,
    options: options
  };
};

export const setVillageOptions = options => {
  return {
    type: actionTypes.SET_VILLAGE_OPTIONS,
    options: options
  };
};

export const setCompanyAgencyOptions = options => {
  return {
    type: actionTypes.SET_COMPANY_AGENCY_OPTIONS,
    options: options
  };
};

export const setJobTitleOptions = options => {
  return {
    type: actionTypes.SET_JOB_TITLE_OPTIONS,
    options: options
  };
};

export const setDailyNormalTransactionsOptions = options => {
  return {
    type: actionTypes.SET_DAILY_NORMAL_TRANSACTIONS_OPTIONS,
    options: options
  };
};

export const setResidenceOptions = options => {
  return {
    type: actionTypes.SET_RESIDENCE_OPTIONS,
    options: options
  };
};

export const setTypeOfWorkOptions = options => {
  return {
    type: actionTypes.SET_TYPE_OF_WORK_OPTIONS,
    options: options
  };
};

export const setEmployeeOptions = options => {
  return {
    type: actionTypes.SET_EMPLOYEE_OPTIONS,
    options: options
  };
};

export const setPurposeOfOpeningAccountOptions = options => {
  return {
    type: actionTypes.SET_PURPOSE_OF_OPENING_ACCOUNT_OPTIONS,
    options: options
  };
};

export const setSourceOfIncomeOptions = options => {
  return {
    type: actionTypes.SET_SOURCE_OF_INCOME_OPTIONS,
    options: options
  };
};

export const setCompanyOptions = options => {
  return {
    type: actionTypes.SET_COMPANY_OPTIONS,
    options: options
  };
};

export const setDependentsOptions = options => {
  return {
    type: actionTypes.SET_DEPENDENTS_OPTIONS,
    options: options
  };
};

export const setMarriageOptions = options => {
  return {
    type: actionTypes.SET_MARRIAGE_OPTIONS,
    options: options
  };
};

export const setRelativesOptions = options => {
  return {
    type: actionTypes.SET_RELATIVES_OPTIONS,
    options: options
  };
};

export const setVerifyPayroll = data => {
  return {
    type: actionTypes.SET_VERIFY_PAYROLL,
    data: data
  };
};

export const setApplicationDetails = data => {
  return {
    type: actionTypes.SET_APPLICATION_DETAILS,
    data: data
  };
};

export const setDocumentDetails = data => {
  return {
    type: actionTypes.SET_DOCUMENT_DETAILS,
    data: data
  };
};

export const setRespPayrollDetails = data => {
  return {
    type: actionTypes.SET_RESP_PAYROLL_DETAILS,
    data: data
  };
};

export const setEktpImg = value => {
  return {
    type: actionTypes.SET_EKTP_IMG,
    value: value
  };
};

export const setPersonInfo = data => {
  return {
    type: actionTypes.SET_PERSON_INFO,
    personInfo: data
  };
};

export const setKtpAddressDetails = data => {
  return {
    type: actionTypes.SET_KTP_ADDRESS_DETAILS,
    data: data
  };
};

export const setOccupationInfo = data => {
  return {
    type: actionTypes.SET_OCCUPATION_INFO,
    occupationInfo: data
  };
};

export const setFamilyInfo = data => {
  return {
    type: actionTypes.SET_FAMILY_INFO,
    familyInfo: data
  };
};

export const setCreditScoringRes = res => {
  return {
    type: actionTypes.CREDIT_SCORE_RESPONSE,
    res: res
  };
};

export const setWhitelistAddress = (whitelistAddress, applicantDetails) => {
  const addresses = whitelistAddress && whitelistAddress.split('|');
  const fullAddress = addresses && addresses.length > 0 ? addresses[0] : '';
  const address = {
    fullAddress,
    haveSameAddress: false
  };

  if (applicantDetails && applicantDetails.addressLine1) {
    Object.assign(address, {
      haveSameAddress: applicantDetails.addressLine1 === fullAddress
    });
  }

  return {
    type: actionTypes.SET_WHITELIST_ADDRESS,
    whitelistAddress: address
  };
};

export const setSecondApply = data => {
  return {
    type: actionTypes.SET_SECOND_APPLY,
    set: data
  };
};

export const setCompanyName = val => {
  return {
    type: actionTypes.SET_COMPANY_NAME,
    data: val
  };
};

export const setCompanyAddress = val => {
  return {
    type: actionTypes.SET_COMPANY_ADDRESS,
    data: val
  };
};

export const dehydrateData = () => {
  return {
    type: actionTypes.SET_DEHYDRATE_DATA
  };
};

export const setSubCodeLoanScheme = payload => {
  return {
    type: actionTypes.SET_SUBCODE_LOANSCHEME,
    payload: payload
  };
};

export const setLoanSchemes = payload => {
  return {
    type: actionTypes.SET_LOAN_SCHEMES,
    payload: payload
  };
};

export const setLoanScheme = payload => {
  return {
    type: actionTypes.SET_LOAN_SCHEME,
    payload: payload
  };
};

export const setLoanFee = payload => {
  return {
    type: actionTypes.SET_LOAN_FEE,
    payload: payload
  };
};

export const setAttemptSubmission = payload => {
  return {
    type: actionTypes.SET_ATTEMPT_SUBMISSION,
    payload
  };
};

export const setShouldManualCheck = payload => {
  return {
    type: actionTypes.SHOULD_MANUAL_CHECK,
    payload
  };
};

export const setNPWP = payload => {
  return {
    type: actionTypes.SET_NPWP,
    payload
  };
};

export const handleAbortResubmitKTP = (isAbort = false) => {
  return dispatch => {
    if (isAbort) {
      controller.abort();
      dispatch(setError('ERR_CANCELED'));
      return;
    }
    controller = new AbortController();
    return;
  };
};

export const setAlterNativeAmount = payload => {
  return {
    type: actionTypes.SET_ALTERNATIVE_AMOUNT,
    payload
  };
};

export const setAlternativeTenure = payload => {
  return {
    type: actionTypes.SET_ALTERNATIVE_TENURE,
    payload
  };
};

export const setRayanetInqRes = payload => {
  return {
    type: actionTypes.SET_RAYANET_INQUIRY,
    payload
  };
};

export const setAccountInformationInq = payload => {
  return {
    type: actionTypes.SET_ACCOUNT_INFORMATION_APP_INQ,
    payload
  };
};

export const setAccountRayaInformation = payload => {
  return {
    type: actionTypes.SET_ACCOUNT_RAYA_INFORMATION,
    payload
  };
};

export const setNpwpImg = value => {
  return {
    type: actionTypes.SET_NPWP_IMAGE,
    value
  };
};

export const setAccountRayaSavingExist = payload => {
  return {
    type: actionTypes.SET_ACCOUNT_RAYA_SAVING_EXIST,
    payload
  };
};

export const setAcctCreatedFromSaving = acctCreatedFromSaving => {
  return {
    type: actionTypes.SET_ACCT_CREATED_FROM_SAVING,
    acctCreatedFromSaving: acctCreatedFromSaving
  };
};

export const setIsExistWhitelist = payload => {
  return {
    type: actionTypes.SET_IS_EXIST_WHITELIST,
    payload
  };
};

export const setKtpSubmitFormRes = payload => {
  return {
    type: actionTypes.SET_KTP_SUBMIT_FORM_RES,
    payload
  };
};

export const setAgreeCheckboxKtp = payload => {
  return {
    type: actionTypes.SET_AGREE_CHECKBOX_KTP,
    payload
  };
};

export const setRespKtpSubmit = payload => {
  return {
    type: actionTypes.SET_RESP_KTP_SUBMIT,
    payload
  };
};

export const setKasbonInqRes = payload => {
  return {
    type: actionTypes.SET_KASBON_INQUIRY,
    payload
  };
};

export const setIsCompanyUpdated = payload => {
  return {
    type: actionTypes.SET_IS_COMPANY_UPDATED,
    payload
  };
};

export const setFlagBoxs = payload => {
  return {
    type: actionTypes.SET_FLAGBOX,
    payload
  };
};

export const setBottomSheet = payload => {
  return {
    type: actionTypes.SET_BOTTOM_SHEET,
    payload
  };
};

export const setBlockScreen = payload => {
  return {
    type: actionTypes.SET_BLOCK_SCREEN,
    payload
  };
};

export const setIsMaintenance = payload => {
  return {
    type: actionTypes.SET_IS_MAINTENANCE,
    payload
  };
};

export const setIsSameContactOther = payload => {
  return {
    type: actionTypes.SET_IS_SAME_CONTACT_OTHER,
    payload
  };
};

export const setWhitelistReferenceVO = payload => {
  return {
    type: actionTypes.SET_WHITELIST_INQUIRY,
    payload
  };
};
export const setDocRiplay = doc => {
  return {
    type: actionTypes.SET_DOC_RIPLAY,
    doc: doc
  };
};

export const setIsCustomerConsent = value => {
  return {
    type: actionTypes.SET_IS_CUSTOMER_CONSENT,
    isCustomerConsent: value
  };
};

export const setIsWordingPmi = value => {
  return {
    type: actionTypes.SET_IS_WORDING_PMI,
    isWordingPmi: value
  };
};

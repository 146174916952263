import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  PrivateRouteAuth,
  PrivateRouteNik,
  PrivateRoutePhoneNumber
} from './components/PrivateRoute';
import WithBottomNav from 'components/withBottomNav';
import WithTransition from 'components/withTransition';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import imported from 'react-imported-component';
import { PrerenderedComponent } from 'react-prerendered-component';
import { isObjectEmpty } from 'helpers/Utility';
import {
  CODE_AUTH_FLOW_RAYA_0001,
  CODE_AUTH_FLOW_RAYA_0006,
  CODE_AUTH_FLOW_RAYA_0007,
  CODE_AUTH_FLOW_RAYA_0008
} from 'common/constants';

/*
 ** FORGOT PIN ROUTES
 */
const ForgotPin = lazy(() =>
  import(/* webpackChunkName: "ForgotPin" */ './views/LoginWrongPin')
);
const ForgotPinInputNewPin = lazy(() =>
  import(
    /* webpackChunkName: "ForgotPinInputNewPin" */ './views/ForgotPin/InputPinNew'
  )
);
const ConfirmForgotPinInputNewPin = lazy(() =>
  import(
    /* webpackChunkName: "ConfirmForgotPinInputNewPin" */ './views/ForgotPin/ConfirmNewPin'
  )
);
const AtmVerification = lazy(() =>
  import(
    /* webpackChunkName: "AtmVerification" */ './views/ForgotPin/AtmVerification'
  )
);

/*
 ** ON BOARDING and AUTHENTICATION ROUTES
 */
const SlideShow = lazy(() =>
  import(
    /* webpackChunkName: "OnBoardSlideShow", webpackPreload: true */ './views/OnBoardSlideShow'
  )
);
const AuthFlowRayaApps = lazy(() =>
  import(/* webpackChunkName: "AuthFlowRayaApps" */ './views/AuthFlowRayaApps')
);
const InputPhoneNumber = lazy(() =>
  import(
    /* webpackChunkName: "InputPhoneNumber" */ './views/RegistrationInputPhoneNumber'
  )
);
const CreatePin = lazy(() =>
  import(/* webpackChunkName: "CreatePin" */ './views/RegisterNewCreatePIN')
);
const ConfirmCreatePin = lazy(() =>
  import(
    /* webpackChunkName: "ConfirmCreatePin" */ './views/RegisterConfirmNewCreatePin'
  )
);
const InputOTPRegistration = lazy(() =>
  import(/* webpackChunkName: "InputOTP" */ './views/InputOTP/OTPRegistration')
);
const InputOTPForgotPin = lazy(() =>
  import(/* webpackChunkName: "InputOTP" */ './views/InputOTP/OTPForgotPin')
);
const InputOTPChangePhoneNumber = lazy(() =>
  import(
    /* webpackChunkName: "InputOTP" */ './views/InputOTP/OTPChangePhoneNumber'
  )
);
const Login = lazy(() =>
  import(/* webpackChunkName: "Login" */ './views/LoginInputPIN')
);
const NoPermissionCamera = lazy(() =>
  import(
    /* webpackChunkName: "NoPermissionCamera" */ './views/NoPermissionCamera'
  )
);

/*
 ** LOAN SUBMISSION ROUTES
 */
const NewLoan = lazy(() =>
  import(
    /* webpackChunkName: "NewLoan" */ './views/LoanSubmissionPlafondTenureRequest'
  )
);
const AccountVerification = lazy(() =>
  import(
    /* webpackChunkName: "AccountVerification" */ './views/LoanSubmissionAccountVerification'
  )
);
const PayrollApprove = lazy(() =>
  import(
    /* webpackChunkName: "PayrollApprove" */ './views/LoanSubmissionAccountVerification/LoanSubmissionPayrollApprove'
  )
);
const PayrollReject = lazy(() =>
  import(
    /* webpackChunkName: "PayrollReject" */ './views/LoanSubmissionAccountVerification/LoanSubmissionPayrollReject'
  )
);
const TipsPengajuan = lazy(() =>
  import(
    /* webpackChunkName: "TipsPengajuan" */ './views/LoanSubmissionAccountVerification/LoanSubmissionTipsPengajuan'
  )
);
const FormFillingV2 = lazy(() =>
  import(
    /* webpackChunkName: "FormFilling" */ './views/LoanSubmissionMainFormV2'
  )
);
const TakeEktpV2 = lazy(() =>
  import(
    /* webpackChunkName: "TakeEktp" */ './views/LoanSubmissionEktpPhotoCaptureV2'
  )
);
const PersonalInformationV3 = lazy(() =>
  import(
    /* webpackChunkName: "PersonalInformation" */ './views/LoanSubmissionPersonalInfoInputFormV3'
  )
);
const OccupationInformationV2 = lazy(() =>
  import(
    /* webpackChunkName: "OccupationInformation" */ './views/LoanSubmissionJobInfoInputFormV2'
  )
);
const FamilyInformationV2 = lazy(() =>
  import(
    /* webpackChunkName: "FamilyInformation" */ './views/LoanSubmissionFamilyInfoInputFormv2'
  )
);
const CreditScoring = lazy(() =>
  import(
    /* webpackChunkName: "CreditScoring" */ './views/Home/Pinjaman/CreditScore'
  )
);
const ContinueApply = lazy(() =>
  import(
    /* webpackChunkName: "ContinueApply" */ 'views/Home/Pinjaman/LanjutkanProsesPengajuan'
  )
);

/*
 ** DISBURSEMENT LOAN ROUTES
 */
const DisbursementAccount = lazy(() =>
  import(
    /* webpackChunkName: "DisbursementAccount" */ './views/DisbursementAccountInfo'
  )
);
const DisbursementSelfie = lazy(() =>
  import(
    /* webpackChunkName: "DisbursementSelfie" */ './views/DisbursementTakeSelfiePhotoRegistration'
  )
);
const DisbursementDocument = lazy(() =>
  import(
    /* webpackChunkName: "DisbursementDocument" */ './views/DisbursementPinangDoc'
  )
);
const RegisterAccountLoad = lazy(() =>
  import(
    /* webpackChunkName: "RegisterAccountLoad" */ './views/DisbursementPinangDoc/RegisterAccountLoad'
  )
);
const PrivyRegistOTP = lazy(() =>
  import(
    /* webpackChunkName: "PrivyRegistOTP" */ './views/DisbursementPrivyRegistrationInputOTP'
  )
);
const DisbursementFace = lazy(() =>
  import(
    /* webpackChunkName: "DisbursementFace" */ './views/DisbursementFaceRegistrationInfo'
  )
);
const DisbursementProcess = lazy(() =>
  import(
    /* webpackChunkName: "DisbursementProcess" */ './views/DisbursementProcess'
  )
);
const DisbursementSuccessful = lazy(() =>
  import(
    /* webpackChunkName: "DisbursementSuccessful" */ './views/DisbursementSuccess'
  )
);
const DisbursementFinish = lazy(() =>
  import(
    /* webpackChunkName: "DisbursementFinish" */ './views/DisbursementComplete'
  )
);
const ContinueDisburse = lazy(() =>
  import(
    /* webpackChunkName: "ContinueDisburse" */ 'views/Home/Pinjaman/LanjutkanProsesPencairan'
  )
);

const MaintenanceScreen = lazy(() =>
  import(
    /* webpackChunkName: "MaintenanceScreen" */ 'views/Home/Pinjaman/MaintenanceScreen'
  )
);

const Liveness = lazy(() =>
  import(
    /* webpackChunkName: "Liveness" */ './views/DisbursementTakeSelfie/PassiveLiveness'
  )
);

/*
 ** FULL ADVANCE REPAYMENT ROUTES
 */
const FullAdvanceRepayment = lazy(() =>
  import(
    /* webpackChunkName: "FullAdvanceRepayment" */ './views/FullAdvanceRepayment'
  )
);
const RepaymentInputPin = lazy(() =>
  import(
    /* webpackChunkName: "RepaymentInputPin" */ './views/FullAdvanceRepayment/RepaymentInputPin'
  )
);
const RepaymentMessage = lazy(() =>
  import(
    /* webpackChunkName: "RepaymentMessage" */ './views/FullAdvanceRepayment/AdvancedRepaymentMessage'
  )
);

/*
 ** SUB MENU ROUTES
 */
const DocLoan = lazy(() =>
  import(
    /* webpackChunkName: "DocLoan" */ './views/Home/Pinjaman/LihatPinjaman/PinangDoc'
  )
);
const ChangePhoneNumber = lazy(() =>
  import(
    /* webpackChunkName: "ChangePhoneNumber" */ './views/ChangePhoneNumber'
  )
);
const RekeningInfo = lazy(() =>
  import(/* webpackChunkName: "RekeningInfo" */ './views/RekeningInfo')
);
const DetailHelp = lazy(() =>
  import(/* webpackChunkName: "DetailHelp" */ './views/Home/Bantuan/Detail')
);
const DetailMessage = lazy(() =>
  import(/* webpackChunkName: "DetailMessage" */ './views/Home/Pesan/Details')
);
const DetailCicilan = lazy(() =>
  import(
    /* webpackChunkName: "DetailCicilan" */ './views/Home/Pinjaman/LihatPinjaman/DetailCicilan'
  )
);
const DetailPayment = lazy(() =>
  import(
    /* webpackChunkName: "DetailPayment" */ './views/Home/Riwayat/Details/payment'
  )
);
const DetailLoan = lazy(() =>
  import(
    /* webpackChunkName: "DetailLoan" */ './views/Home/Riwayat/Details/loan'
  )
);
const EditEktp = lazy(() =>
  import(
    /* webpackChunkName: "EditEktp" */ './views/SecondLoan/SecondLoanEditEktpPhoto'
  )
);
const LoanCreateFail = lazy(() =>
  import(
    /* webpackChunkName: "LoanCreateFail" */ './views/Home/Pinjaman/LoanCreateFailed'
  )
);

/*
 ** CHANGE PIN ROUTES
 */
const LoginChangePin = lazy(() =>
  import(
    /* webpackChunkName: "LoginChangePin" */ './views/ChangeNewPin/LoginChangePin'
  )
);
const InputChangePin = lazy(() =>
  import(
    /* webpackChunkName: "InputChangePin" */ './views/ChangeNewPin/InputChangePin'
  )
);
const ConfirmChangePin = lazy(() =>
  import(
    /* webpackChunkName: "ConfirmChangePin" */ './views/ChangeNewPin/ConfirmChangePin'
  )
);

/*
 ** HOME PAGE NAVIGATION ROUTES
 */
const Home = lazy(() =>
  import(/* webpackChunkName: "Home" */ './views/Home/Pinjaman')
);
const History = lazy(() =>
  import(/* webpackChunkName: "History" */ './views/Home/Riwayat')
);
const Notification = lazy(() =>
  import(/* webpackChunkName: "Notification" */ './views/Home/Pesan')
);
const Profile = lazy(() =>
  import(/* webpackChunkName: "Profile" */ './views/Home/Profile')
);
const Help = lazy(() =>
  import(/* webpackChunkName: "Help" */ './views/Home/Bantuan')
);
const LoanDashboard = lazy(() =>
  import(
    /* webpackChunkName: "PinangDashboard" */ './views/Home/Pinjaman/LihatPinjaman'
  )
);

const ExceptionHandling = imported(() =>
  import(
    /* webpackChunkName: "ExceptionHandling" */ './views/ExceptionalHandling'
  )
);

const AccountInquiry = lazy(() =>
  import(
    /* webpackChunkName: "AccountInquiry" */ './views/LoanSubmissionAccountInquiry'
  )
);
const RegisterWhitelist = lazy(() =>
  import(
    /* webpackChunkName: "RegisterWhitelist" */ './views/RegisterWhitelist'
  )
);
const SuccessSubmitWhitelist = lazy(() =>
  import(
    /* webpackChunkName: "SuccessSubmitWhitelist" */ './views/RegisterWhitelist/SuccessSubmitWhitelist'
  )
);

const RefusalReason = lazy(() =>
  import(
    /* webpackChunkName: "RefusalReason" */ './views/Home/Pinjaman/CreditScore/Approved/RefusalReason'
  )
);

const OfferingV2 = lazy(() =>
  import(
    /* webpackChunkName: "OfferingV2" */ './views/Home/Pinjaman/CreditScore/OfferingV2'
  )
);

const HandleScheme = lazy(() =>
  import(/* webpackChunkName: "OfferingV2" */ './views/HandleScheme')
);

const BannerFaq = lazy(() =>
  import(/* webpackChunkName: "OfferingV2" */ './views/Home/BannerFaq')
);

const Routes = props => {
  const {
    token,
    phoneNo,
    phoneNoRaya,
    subCode,
    isRayaAgreement,
    dataRayaAppsAuthFlow,
    nikFlexi,
    nikRaya,
    codeAuthFlowRaya
  } = props;

  useGoogleAnalytics();

  //initial
  let root = '';

  // code flow not allowed is raya agreement
  const notAllowedIsRayaAgreement = [
    CODE_AUTH_FLOW_RAYA_0001,
    CODE_AUTH_FLOW_RAYA_0006,
    CODE_AUTH_FLOW_RAYA_0007,
    CODE_AUTH_FLOW_RAYA_0008
  ];

  // if phoneNo is saved in localStorage
  if (subCode === 'RAYA' && !isObjectEmpty(dataRayaAppsAuthFlow)) {
    if (phoneNo || phoneNoRaya) {
      if (
        isRayaAgreement &&
        !notAllowedIsRayaAgreement.includes(codeAuthFlowRaya)
      ) {
        root = (
          <PrivateRoutePhoneNumber
            component={Login}
            exact
            path="/"
            phoneNo={phoneNo}
          />
        );
      } else {
        root = (
          <PrivateRoutePhoneNumber
            component={AuthFlowRayaApps}
            exact
            path="/"
            phoneNo={phoneNo || phoneNoRaya}
          />
        );
      }
    } else {
      if (nikFlexi || nikRaya) {
        root = (
          <PrivateRouteNik
            component={AuthFlowRayaApps}
            exact
            nik={nikFlexi || nikRaya}
            path="/"
          />
        );
      }
    }
  } else {
    if (phoneNo) {
      root = (
        <PrivateRoutePhoneNumber
          component={Login}
          exact
          path="/"
          phoneNo={phoneNo}
        />
      );
    }
  }

  return (
    <PrerenderedComponent live={ExceptionHandling.preload()}>
      <Switch>
        {root}
        <Route component={SlideShow} exact path="/" />
        <Route component={InputPhoneNumber} exact path="/input-phone-number" />
        <Route
          component={MaintenanceScreen}
          exact
          path="/maintenance"
          token={token}
        />

        <PrivateRouteAuth
          component={HandleScheme}
          exact
          path="/different-scheme"
          token={token}
        />

        <PrivateRouteAuth
          component={RefusalReason}
          exact
          path="/refuse-reason"
          token={token}
        />

        <PrivateRouteAuth
          component={OfferingV2}
          exact
          path="/offering"
          token={token}
        />

        <PrivateRouteAuth
          component={AccountInquiry}
          exact
          path="/account-inquiry"
          token={token}
        />
        <PrivateRouteAuth
          component={RegisterWhitelist}
          exact
          path="/register-whitelist"
          token={token}
        />
        <PrivateRouteAuth
          component={SuccessSubmitWhitelist}
          exact
          path="/whitelist-success"
          token={token}
        />
        <PrivateRouteAuth
          component={RepaymentMessage}
          exact
          path="/repayment-message"
          token={token}
        />
        <PrivateRouteAuth
          component={Liveness}
          exact
          path="/liveness"
          token={token}
        />
        <PrivateRoutePhoneNumber
          component={CreatePin}
          exact
          path="/create-pin"
          phoneNo={phoneNo}
        />
        <PrivateRoutePhoneNumber
          component={ConfirmCreatePin}
          exact
          path="/confirm-create-pin"
          phoneNo={phoneNo}
        />
        <PrivateRoutePhoneNumber
          component={Login}
          exact
          path="/login"
          phoneNo={phoneNo}
        />
        <PrivateRoutePhoneNumber
          component={ChangePhoneNumber}
          exact
          path="/change-phone-number-raya-auth"
          phoneNo={phoneNo}
        />
        <PrivateRouteAuth
          component={NoPermissionCamera}
          exact
          path="/no-permission-camera"
          token={token}
        />
        <PrivateRouteAuth
          component={NewLoan}
          exact
          path="/new-loan"
          token={token}
        />
        <PrivateRouteAuth
          component={AccountVerification}
          exact
          path="/account-verif"
          token={token}
        />
        <PrivateRouteAuth
          component={FormFillingV2}
          exact
          path="/form-fill"
          token={token}
        />
        <PrivateRouteAuth
          component={TakeEktpV2}
          exact
          path="/take-e-ktp"
          token={token}
        />
        <PrivateRouteAuth
          component={PersonalInformationV3}
          exact
          path="/person-info"
          token={token}
        />
        <PrivateRouteAuth
          component={OccupationInformationV2}
          exact
          path="/occupation-info"
          token={token}
        />
        <PrivateRouteAuth
          component={FamilyInformationV2}
          exact
          path="/family-info"
          token={token}
        />
        <PrivateRouteAuth
          component={ContinueApply}
          exact
          path="/continue-apply"
          token={token}
        />
        <PrivateRouteAuth
          component={CreditScoring}
          exact
          path="/credit-scoring"
          token={token}
        />
        <PrivateRouteAuth
          component={ContinueDisburse}
          exact
          path="/continue-disburse"
          token={token}
        />
        <PrivateRouteAuth
          component={LoanCreateFail}
          exact
          path="/loanCreate-fail"
          token={token}
        />
        <PrivateRouteAuth
          component={DisbursementFace}
          exact
          path="/disburse-face"
          token={token}
        />
        <PrivateRouteAuth
          component={DisbursementAccount}
          exact
          path="/disburse-acc"
          token={token}
        />
        <PrivateRouteAuth
          component={DisbursementSelfie}
          exact
          path="/disburse-selfie"
          token={token}
        />
        <PrivateRouteAuth
          component={RegisterAccountLoad}
          exact
          path="/register-load"
          token={token}
        />
        <PrivateRouteAuth
          component={DisbursementDocument}
          exact
          path="/disburse-doc"
          token={token}
        />
        <PrivateRoutePhoneNumber
          component={InputOTPRegistration}
          exact
          path="/input-otp/registration"
          phoneNo={phoneNo}
        />
        <PrivateRoutePhoneNumber
          component={InputOTPForgotPin}
          exact
          path="/input-otp/forgot-pin"
          phoneNo={phoneNo}
        />
        <PrivateRoutePhoneNumber
          component={InputOTPChangePhoneNumber}
          exact
          path="/input-otp/change-number"
          phoneNo={phoneNo}
        />
        <PrivateRouteAuth
          component={PrivyRegistOTP}
          exact
          path="/privy-reg-otp"
          token={token}
        />
        <PrivateRouteAuth
          component={DisbursementProcess}
          exact
          path="/disburse-process"
          token={token}
        />
        <PrivateRouteAuth
          component={DisbursementSuccessful}
          exact
          path="/disburse-success"
          token={token}
        />
        <PrivateRouteAuth
          component={DisbursementFinish}
          exact
          path="/disburse-fin"
          token={token}
        />
        <PrivateRoutePhoneNumber
          component={ForgotPin}
          exact
          path="/forgot-pin"
          phoneNo={phoneNo}
        />
        <PrivateRouteAuth
          component={LoanDashboard}
          exact
          path="/dashboard"
          token={token}
        />
        <PrivateRouteAuth
          component={DocLoan}
          exact
          path="/doc-loan"
          token={token}
        />
        <PrivateRouteAuth
          component={DetailPayment}
          exact
          path="/detail-payment"
          token={token}
        />
        <PrivateRouteAuth
          component={DetailLoan}
          exact
          path="/detail-loan"
          token={token}
        />
        <PrivateRouteAuth
          component={FullAdvanceRepayment}
          exact
          path="/full-advance-repayment"
          token={token}
        />
        <PrivateRouteAuth
          component={RepaymentInputPin}
          exact
          path="/repayment-input-pin"
          token={token}
        />
        <Route component={ExceptionHandling} exact path="/exception-handling" />
        <PrivateRoutePhoneNumber
          component={ForgotPinInputNewPin}
          exact
          path="/forgot-pin/input-new-pin"
          phoneNo={phoneNo}
        />
        <PrivateRoutePhoneNumber
          component={ConfirmForgotPinInputNewPin}
          exact
          path="/forgot-pin/confirm-new-pin"
          phoneNo={phoneNo}
        />
        <PrivateRoutePhoneNumber
          component={AtmVerification}
          exact
          path="/forgot-pin/atm-verification"
          phoneNo={phoneNo}
        />
        <PrivateRouteAuth
          component={EditEktp}
          exact
          path="/edit-foto-ktp"
          token={token}
        />
        <PrivateRouteAuth
          component={DetailMessage}
          exact
          path="/detail-message"
          token={token}
        />
        <PrivateRouteAuth
          component={DetailHelp}
          exact
          path="/detail-help"
          token={token}
        />
        <PrivateRouteAuth
          component={DetailCicilan}
          exact
          path="/detail-cicilan"
          token={token}
        />
        <PrivateRouteAuth
          component={PayrollReject}
          exact
          path="/payroll-reject"
          token={token}
        />
        <PrivateRouteAuth
          component={TipsPengajuan}
          exact
          path="/tips-pengajuan"
          token={token}
        />
        <PrivateRouteAuth
          component={PayrollApprove}
          exact
          path="/payroll-app"
          token={token}
        />
        <PrivateRouteAuth
          component={RekeningInfo}
          exact
          path="/info-rekening"
          token={token}
        />
        <PrivateRouteAuth
          component={LoginChangePin}
          exact
          path="/change-pin"
          token={token}
        />
        <PrivateRouteAuth
          component={InputChangePin}
          exact
          path="/input-change-pin"
          token={token}
        />
        <PrivateRouteAuth
          component={ConfirmChangePin}
          exact
          path="/confirm-change-pin"
          token={token}
        />
        <PrivateRouteAuth
          component={ChangePhoneNumber}
          exact
          path="/change-phone-number"
          token={token}
        />
        <PrivateRouteAuth
          component={BannerFaq}
          exact
          path="/banner-faq"
          token={token}
        />

        <WithTransition>
          <Switch>
            <PrivateRouteAuth
              component={Home}
              exact
              path="/home"
              token={token}
            />
            <PrivateRouteAuth
              component={History}
              exact
              path="/history"
              token={token}
            />
            <PrivateRouteAuth
              component={Notification}
              exact
              path="/notification"
              token={token}
            />
            <PrivateRouteAuth
              component={Profile}
              exact
              path="/profile"
              token={token}
            />
            <PrivateRouteAuth
              component={Help}
              exact
              path="/help"
              token={token}
            />
          </Switch>
          <WithBottomNav />
        </WithTransition>
        <Redirect to="/" />
      </Switch>
    </PrerenderedComponent>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.oauthToken,
    phoneNo: state.auth.phoneNo,
    phoneNoRaya: state.auth.noHpRaya,
    subCode: state.auth.subCode,
    isRayaAgreement: state.auth.isRayaAgreement,
    dataRayaAppsAuthFlow: state.auth.dataRayaAppsAuthFlow,
    nikFlexi: state.loanApply.eKTP,
    nikRaya: state.loanApply.eKTPRaya,
    codeAuthFlowRaya: state.auth.codeAuthFlowRaya
  };
};

export default connect(mapStateToProps)(Routes);

import React from 'react';

// Material components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

// Material helpers
import withStyles from '@material-ui/core/styles/withStyles';

// Custom Component
import Paper from '../Paper';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Component styles
const styles = theme => {
  return {
    cardBox: {
      marginBottom: theme.spacing(1)
    },
    cardBoxContent: {
      borderRadius: '6px',
      padding: 0,
      paddingBottom: 0
    },
    titleContent: {
      borderRadius: '6px',
      padding: theme.spacing(1)
    },
    titleText: {
      color: theme.palette.common.white,
      fontWeight: 700
    },
    // Message Box for Red Color
    colorBgRedTitle: {
      backgroundColor: theme.palette.text.error
    },
    colorRed: {
      backgroundColor: theme.palette.error.light
    },

    // Message Box for Orange Color
    colorOrange: {
      backgroundColor: theme.palette.secondary.light
    },
    borderOrange: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '8px'
    },

    // Message Box for Blue Color
    colorBgBlueTitle: {
      backgroundColor: theme.palette.info.stateInfoRaya
    },
    colorBlue: {
      backgroundColor: theme.palette.common.brightCeruleaRaya
    },

    // Message Box for Bright Blue Color
    colorBrightBlue: {
      backgroundColor: theme.palette.common.brightCeruleaRaya
    },
    borderBrightBlue: {
      border: `1px solid ${theme.palette.info.main}`,
      borderRadius: '8px'
    },

    // Message Box for Success Color
    colorBgSuccessTitle: {
      backgroundColor: theme.palette.success.main
    },
    colorSuccess: {
      backgroundColor: theme.palette.success.light
    },
    borderRed: {
      border: `1px solid ${theme.palette.common.stateErrorRaya}`,
      borderRadius: '8px'
    }
  };
};

const MessageBox = ({
  classes,
  className,
  title,
  Red,
  children,
  Orange,
  Blue,
  Success,
  BorderBrightBlue,
  BrightBlue,
  BorderOrange,
  BorderRed
}) => {
  const rootClassName = classNames(
    {
      [classes.cardBox]: true
    },
    className
  );

  const contentClassName = classNames({
    [classes.cardBoxContent]: true,
    [classes.colorRed]: Red,
    [classes.colorOrange]: Orange,
    [classes.borderOrange]: Orange,
    [classes.colorBlue]: Blue,
    [classes.colorSuccess]: Success,
    [classes.colorBrightBlue]: BrightBlue,
    [classes.borderBrightBlue]: BorderBrightBlue,
    [classes.borderOrange]: BorderOrange,
    [classes.borderRed]: BorderRed
  });

  const titleClassName = classNames({
    [classes.titleContent]: true,
    [classes.colorBgRedTitle]: Red,
    [classes.colorBgBlueTitle]: Blue,
    [classes.colorBgSuccessTitle]: Success
  });

  return (
    <>
      <Card className={rootClassName} elevation={0}>
        <CardContent className={contentClassName}>
          {title && (
            <Paper className={titleClassName} elevation={0} squared>
              <Typography
                align="center"
                className={classes.titleText}
                variant="body2"
              >
                {title}
              </Typography>
            </Paper>
          )}
          {children}
        </CardContent>
      </Card>
    </>
  );
};

MessageBox.propTypes = {
  Blue: PropTypes.bool,
  BorderBrightBlue: PropTypes.bool,
  BorderOrange: PropTypes.bool,
  BorderRed: PropTypes.bool,
  Orange: PropTypes.bool,
  Red: PropTypes.bool,
  Success: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
  title: PropTypes.string
};

export default withStyles(styles)(MessageBox);

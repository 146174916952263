/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  TextField,
  InputAdornment,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  Grid,
  FilledInput,
  Typography
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { Paper } from '../../components';
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import palettes from '../../theme/palettes/index';
import { getPartnerId } from 'helpers/getPartnerId';
const FilledInputTenor = withStyles({
  root: {
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: '#F6F9FD'
      }
    }
  }
})(FilledInput);

const withValueCap = props => inputObj => {
  const { value } = inputObj;
  if (props.max) {
    if (value <= props.max) {
      return inputObj;
    }
  } else {
    return inputObj;
  }
};

const getUpdateDataText = (classes, props) => {
  const { updateDataText } = props;

  return (
    <Grid
      alignItems="center"
      className={classes.errorText}
      container
      direction="row"
    >
      <Grid item>
        <img
          src="/images/assets/warning_update_data.svg"
          style={{ margin: 'auto', display: 'block' }}
        />
      </Grid>
      <Grid item style={{ marginLeft: '5px' }} xs={10}>
        {updateDataText}
      </Grid>
    </Grid>
  );
};

const RegularNumberFormat = props => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      allowLeadingZeros={false}
      allowNegative={false}
      decimalScale={0}
      getInputRef={inputRef}
      isNumericString
      onChange={onChange}
    />
  );
};

const PhoneNumberFormat = props => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      allowLeadingZeros
      allowNegative={false}
      decimalScale={0}
      getInputRef={inputRef}
      maxLength={props.id === 'workPhoneNumber' ? 16 : 13}
      minLength={10}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
    />
  );
};

const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isAllowed={withValueCap(props)}
      isNumericString
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
    />
  );
};

const NumberFormatCurrency = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowNegative={false}
      decimalSeparator={false}
      getInputRef={inputRef}
      isAllowed={withValueCap(props)}
      isNumericString
      maxLength={12}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator={'.'}
    />
  );
};

const NumberFormatExpDate = props => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      // style={{ paddingBottom: 24 }}
      format="## / ##"
      getInputRef={inputRef}
      isNumericString
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
    />
  );
};

const AccountNumberFormat = props => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      allowEmptyFormatting={false}
      format="####-##-######-##-#"
      getInputRef={inputRef}
      isNumericString
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
            id: props.id
          }
        });
      }}
    />
  );
};

const NpwpNumberFormat = props => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      allowEmptyFormatting={false}
      format="##.###.###.#-###.####"
      getInputRef={inputRef}
      isNumericString
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
            id: props.id
          }
        });
      }}
    />
  );
};

const DefaultInput = params => {
  const { classes, props } = params;

  const helperText = (
    <span className={classes.helperText}>{props.helperText}</span>
  );
  const errorText = (
    <span className={classes.errorText}>{props.errorText}</span>
  );

  const getHelperTextDefaultInput = () => {
    if (props.isUpdateData) return getUpdateDataText(classes, props);
    if (!props.isError) return helperText;
    else return errorText;
  };

  return (
    <TextField
      InputProps={{
        startAdornment: props.adornment && (
          <InputAdornment position="start">{props.adornment}</InputAdornment>
        ),
        notched: false,
        style: props.style ? props.style : {}
      }}
      autoComplete="off"
      className={props.className}
      disabled={props.disabled}
      error={props.isError || props.isUpdateData}
      fullWidth
      helperText={getHelperTextDefaultInput()}
      id={props.id}
      inputProps={props.inputProps}
      // label={<p className={classes.labelInput}>{props.label}</p>}
      onChange={props.onChange}
      placeholder={props.placeholder}
      type={props.type}
      value={props.value}
      variant={props.variant ? props.variant : 'outlined'}
    />
  );
};

const DefaultInputTextArea = params => {
  const { classes, props } = params;

  const helperText = (
    <span className={classes.helperText}>{props.helperText}</span>
  );

  const errorText = (
    <span className={classes.errorText}>{props.errorText}</span>
  );

  const getHelperTextDefaultInputTextArea = () => {
    if (props.isUpdateData) return getUpdateDataText(classes, props);
    if (!props.isError) return helperText;
    else return errorText;
  };

  return (
    <TextField
      InputProps={{
        startAdornment: props.adornment && (
          <InputAdornment position="start">{props.adornment}</InputAdornment>
        ),
        notched: false,
        style: props.style ? props.style : {}
      }}
      autoComplete="off"
      className={props.className}
      disabled={props.disabled}
      error={props.isError || props.isUpdateData}
      fullWidth
      helperText={getHelperTextDefaultInputTextArea()}
      id={props.id}
      inputProps={props.inputProps}
      minRows={4}
      multiline
      onChange={props.onChange}
      placeholder={props.placeholder}
      type={props.type}
      value={props.value}
      variant={props.variant ? props.variant : 'outlined'}
    />
  );
};

const DefaultInputAdorment = params => {
  const { classes, props } = params;

  const helperText = (
    <span className={classes.helperText}>{props.helperText}</span>
  );
  const errorText = (
    <span className={classes.errorText}>{props.errorText}</span>
  );

  return (
    <TextField
      InputProps={{
        startAdornment: props.adornment && !props.adornmentPosition && (
          <InputAdornment position="start">{props.adornment}</InputAdornment>
        ),
        endAdornment: props.adornment && props.adornmentPosition === 'end' && (
          <InputAdornment position="end">{props.adornment}</InputAdornment>
        ),
        style: props.style ? props.style : {},
        notched: false
      }}
      autoComplete="off"
      className={props.className}
      disabled={props.disabled}
      error={props.isError}
      fullWidth
      helperText={!props.isError ? helperText : errorText}
      id={props.id}
      inputProps={props.inputProps}
      label={<p className={classes.labelInput}>{props.label}</p>}
      onChange={props.onChange}
      placeholder={props.placeholder}
      type={props.type}
      value={props.value}
      variant={props.variant ? props.variant : 'outlined'}
    />
  );
};

const RcheckedInput = params => {
  const { classes, props } = params;

  return (
    <Paper
      className={classes.paperCheck + ' ' + props.className}
      squared={false}
      variant="outlined"
    >
      <Grid alignItems="center" container>
        <Grid item xs={10}>
          <Typography align="left">{props.label}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Checkbox
            checked={props.checked}
            color="primary"
            defaultChecked
            id={props.id}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={props.onChange}
            value={props.value}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const CheckedInput = params => {
  const { classes, props } = params;

  return (
    <Paper
      className={classes.paperCheck + ' ' + props.className}
      elevation={0}
      squared={false}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checked}
            id={props.id}
            onChange={props.onChange}
            value={props.value}
          />
        }
        disabled={props.disabled}
        label={
          typeof props.label === 'string' ? (
            <Typography className={classes.labelCheck}>
              {props.label}
            </Typography>
          ) : (
            props.label
          )
        }
        labelPlacement={props.labelPlacement ? props.labelPlacement : 'end'}
      />
    </Paper>
  );
};

const AtmDateExpiredInput = params => {
  const { classes, props } = params;

  return (
    <TextField
      InputProps={{
        inputComponent: NumberFormatExpDate
      }}
      autoComplete="off"
      className={props.className}
      error={props.error}
      fullWidth
      helperText={
        <span className={classes.helperText}>{props.helperText}</span>
      }
      id={props.id}
      inputProps={props.inputProps}
      label={<p className={classes.labelInput}>{props.label}</p>}
      onChange={props.onChange}
      placeholder={props.placeholder}
      value={props.value ? props.value : ' '}
      variant="filled"
    />
  );
};

const AccountNumberInput = params => {
  const { classes, props } = params;
  return (
    <TextField
      InputProps={{
        inputComponent: AccountNumberFormat
      }}
      autoComplete="off"
      className={props.className}
      disabled={props.disabled}
      error={props.error}
      fullWidth
      helperText={
        <span className={classes.helperText}>{props.helperText}</span>
      }
      id={props.id}
      inputProps={props.inputProps}
      label={<p className={classes.labelInput}>{props.label}</p>}
      onChange={props.onChange}
      placeholder={props.placeholder}
      value={props.value ? props.value : ''}
      variant="outlined"
    />
  );
};

const NpwpNumberInput = params => {
  const { classes, props } = params;
  return (
    <TextField
      InputProps={{
        inputComponent: NpwpNumberFormat,
        style: props.style ? props.style : {}
      }}
      autoComplete="off"
      className={props.className}
      disabled={props.disabled}
      error={props.isError || props.isUpdateData}
      fullWidth
      helperText={
        <span
          className={classes.helperText}
          style={{
            color: props.isError
              ? palettes[getPartnerId]?.error.main
              : palettes[getPartnerId]?.text.primary
          }}
        >
          {!props.isError && !props.isUpdateData
            ? props.helperText
            : props.errorText}
        </span>
      }
      id={props.id}
      inputProps={props.inputProps}
      label={<p className={classes.labelInput}>{props.label}</p>}
      onChange={props.onChange}
      placeholder={props.placeholder}
      value={props.value ? props.value : ''}
      variant="outlined"
    />
  );
};

const CurrencyInputAdornment = params => {
  const { classes, props } = params;

  // Handling on change from view
  const onChange = event => {
    const updatedEvent = event;
    const value = updatedEvent.target.value.toString();
    if (parseInt(value) > props.max) {
      updatedEvent.target.value = '';
    }

    props.onChange(updatedEvent);
  };
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <Typography
            color="textPrimary"
            style={{ marginRight: '4px' }}
            variant="h5"
          >
            Rp
          </Typography>
        ),
        inputComponent: NumberFormatCurrency,
        style: props.style ? props.style : {}
      }}
      autoComplete="off"
      className={props.className}
      error={props.error}
      fullWidth
      helperText={
        <span className={classes.helperText}>{props.helperText}</span>
      }
      id={props.id}
      inputProps={props.inputProps}
      label={
        props.value.length > 0 && (
          <p className={classes.labelInput}>{props.label}</p>
        )
      }
      onChange={onChange}
      placeholder={props.placeholder}
      value={props.value}
      variant={props.variant ? props.variant : 'outlined'}
    />
  );
};

const CurrencyInput = params => {
  const { classes, props } = params;

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <Typography
            color="textPrimary"
            style={{ marginRight: '4px' }}
            variant="h5"
          >
            Rp
          </Typography>
        ),
        inputComponent: NumberFormatCurrency,
        style: props.style ? props.style : {}
      }}
      autoComplete="off"
      className={props.className}
      error={props.isError}
      fullWidth
      helperText={
        <span
          className={classes.helperText}
          style={{
            color: props.isError
              ? palettes[getPartnerId]?.error.main
              : palettes[getPartnerId]?.text.primary
          }}
        >
          {!props.isError ? props.helperText : props.errorText}
        </span>
      }
      id={props.id}
      inputProps={props.inputProps}
      label={props.label && <p className={classes.labelInput}>{props.label}</p>}
      onChange={props.onChange}
      placeholder={props.placeholder}
      style={{
        paddingTop: 4
      }}
      value={props.value}
      variant={props.variant ? props.variant : 'outlined'}
    />
  );
};

const SelectedInputAdornment = params => {
  const { classes, props } = params;
  let inner = Object.keys(props.options).map(key => (
    <option
      disabled={Number(key) === 0 && !props.options[key].value}
      key={key}
      value={props.options[key].value}
    >
      {'\xa0\xa0\xa0' + props.options[key].label}
    </option>
  ));
  return (
    <FormControl
      className={props.className}
      disabled={props.disabled}
      fullWidth
      variant={props.variant ? props.variant : 'outlined'}
    >
      <InputLabel className={classes.labelInput} id={props.id}>
        {props.label}
      </InputLabel>
      {props.adornmentPosition === 'right' ? (
        <Select
          defaultValue="kontrakan"
          endAdornment={
            <InputAdornment position="end">
              <Grid
                alignItems="center"
                container
                spacing={1}
                style={{ maxWidth: 72 }}
              >
                <Grid item>{props.adornment}</Grid>
              </Grid>
            </InputAdornment>
          }
          error={props.isError}
          id={props.id}
          inputProps={props.inputProps}
          label={props.label}
          name={props.id}
          native
          onChange={props.onChange}
          value={props.value}
        >
          <option disabled value="">
            {'\xa0\xa0\xa0' + props.placeholder}
          </option>
          {inner}
        </Select>
      ) : (
        <Select
          error={props.isError}
          id={props.id}
          inputProps={props.inputProps}
          label={props.label}
          name={props.id}
          native
          onChange={props.onChange}
          startAdornment={<InputAdornment>{props.adornment}</InputAdornment>}
          value={props.value}
        >
          <option disabled value="">
            {'\xa0\xa0\xa0' + props.placeholder}
          </option>
          {inner}
        </Select>
      )}
      <FormHelperText className={classes.helperText}>
        {!props.isError ? props.helperText : props.errorText}
      </FormHelperText>
    </FormControl>
  );
};

const SelectedInput = params => {
  const { classes, props } = params;

  return (
    <FormControl
      className={props.className}
      disabled={props.disabled}
      fullWidth
      style={{
        border: 0
      }}
      variant={props.variant ? props.variant : 'outlined'}
    >
      <InputLabel className={classes.labelInput} id={props.id}>
        {props.label}
      </InputLabel>
      <Select
        error={props.isError}
        id={props.id}
        // inputProps={props.inputProps}
        inputProps={{
          name: props.id,
          id: props.id
        }}
        label={props.label}
        name={props.id}
        native
        onChange={props.onChange}
        style={{ border: 0 }}
        value={props.value}
        variant={props.variant ? props.variant : 'outlined'}
      >
        {Object.keys(props.options).map(key => (
          <option
            disabled={Number(key) === 0 && !props.options[key].value}
            key={key}
            label={props.options[key].label}
            value={props.options[key].value}
          >
            {'\xa0\xa0\xa0' + props.options[key].label}
          </option>
        ))}
      </Select>
      <FormHelperText className={classes.helperText}>
        {!props.isError ? props.helperText : props.errorText}
      </FormHelperText>
    </FormControl>
  );
};

const SelectedTenor = params => {
  const { classes, props } = params;
  return (
    <FormControl
      className={props.className}
      disabled={props.disabled}
      fullWidth
      variant={props.variant ? props.variant : 'outlined'}
    >
      {/* <StyledAdornmentLabel
        id={props.labelId}
        shrink={props.value ? true : false}
      >
        {props.label}
      </StyledAdornmentLabel> */}
      <InputLabel className={classes.labelInput} id={props.id}>
        {props.label}
      </InputLabel>
      <Select
        error={props.isError}
        id={props.id}
        input={<FilledInputTenor id={props.id} name={props.id} />}
        inputProps={props.inputProps}
        label={props.label}
        name={props.id}
        native
        onChange={props.onChange}
        // startAdornment={<InputAdornment>
        //   <Grid
        //     alignItems="center"
        //     container
        //     spacing={1}
        //     style={{ maxWidth: 72 }}
        //   >
        //     <Grid item>{props.adornment}</Grid>
        //   </Grid>
        // </InputAdornment>
        // }
        value={props.value}
      >
        {Object.keys(props.options).map(key => (
          <option
            disabled={Number(key) === 0 && !props.options[key].value}
            key={key}
            value={props.options[key].value}
          >
            {'\xa0\xa0\xa0' + props.options[key].label}
          </option>
        ))}
      </Select>
      <FormHelperText className={classes.helperText}>
        {!props.isError ? props.helperText : props.errorText}
      </FormHelperText>
    </FormControl>
  );
};

const NumberInput = params => {
  const { classes, props } = params;

  const helperText = (
    <span className={classes.helperText}>{props.helperText}</span>
  );
  const errorText = (
    <span className={classes.errorText}>{props.errorText}</span>
  );

  // Handling on change from view
  const onChangeNumberInput = event => {
    const updatedEvent = event;
    const value = updatedEvent.target.value.toString();
    if (props.zeroLeadingsNotAllowed && parseInt(value) < 1) {
      updatedEvent.target.value = '';
    }

    if (parseInt(value) < props.min) {
      updatedEvent.target.value = '';
    }
    if (parseInt(value) > props.max) {
      updatedEvent.target.value = props.max.toString();
    }

    props.onChange(updatedEvent);
  };

  const getHelperTextNumberInput = () => {
    if (props.isUpdateData) return getUpdateDataText(classes, props);
    if (!props.isError) return helperText;
    else return errorText;
  };

  return (
    <TextField
      InputProps={{
        startAdornment: props.adornment && !props.adornmentPosition && (
          <InputAdornment position="start">{props.adornment}</InputAdornment>
        ),
        endAdornment: props.adornment && props.adornmentPosition === 'end' && (
          <InputAdornment position="start">{props.adornment}</InputAdornment>
        ),
        inputComponent: props.max ? NumberFormatCustom : RegularNumberFormat,
        style: props.style ? props.style : {},
        notched: props.notched
      }}
      autoComplete="off"
      className={props.className}
      disabled={props.disabled}
      error={props.isError || props.isUpdateData}
      fullWidth
      helperText={getHelperTextNumberInput()}
      id={props.id}
      inputProps={props.inputProps}
      label={<p className={classes.labelInput}>{props.label}</p>}
      onChange={onChangeNumberInput}
      placeholder={props.placeholder}
      value={props.value}
      variant={props.variant ? props.variant : 'outlined'}
    />
  );
};

const PhoneNumberPrefix = params => {
  const { classes, props } = params;

  const helperText = (
    <span className={classes.helperText}>{props.helperText}</span>
  );
  const errorText = (
    <span className={classes.errorText}>{props.errorText}</span>
  );

  // Handling on change from view
  const onChangePhoneNumberPrefix = event => {
    const updatedEvent = event;
    const value = updatedEvent.target.value.toString();

    if (props.zeroLeadingsNotAllowed && parseInt(value) < 1) {
      updatedEvent.target.value = '';
    }

    if (parseInt(value) < props.min) {
      updatedEvent.target.value = '';
    }

    if (parseInt(value) > props.max) {
      updatedEvent.target.value = props.max.toString();
    }

    props.onChange(updatedEvent);
  };

  return (
    <TextField
      InputProps={{
        startAdornment: props.adornment ? (
          <Grid
            alignItems="center"
            container
            spacing={1}
            style={{ maxWidth: 72 }}
          >
            <Grid item>{props.adornment}</Grid>
            <Grid className={classes.phone_62} item>
              (+62)
            </Grid>
          </Grid>
        ) : (
          <InputAdornment className={classes.phone_62}>(+62)</InputAdornment>
        ),
        endAdornment: props.endAdornment && (
          <InputAdornment>{props.endAdornment}</InputAdornment>
        ),
        inputComponent: RegularNumberFormat,
        style: props.style ? props.style : {}
      }}
      autoComplete="off"
      className={props.className}
      error={props.isError}
      fullWidth
      helperText={!props.isError ? helperText : errorText}
      id={props.id}
      inputProps={props.inputProps}
      label={
        props.label ? <p className={classes.labelInput}>{props.label}</p> : ''
      }
      onChange={onChangePhoneNumberPrefix}
      placeholder={props.placeholder}
      value={props.value}
      variant={props.variant ? props.variant : 'outlined'}
    />
  );
};

const PhoneNumberInput = params => {
  const { classes, props } = params;

  const helperText = (
    <span className={classes.helperText}>{props.helperText}</span>
  );
  const errorText = (
    <span className={classes.errorText}>{props.errorText}</span>
  );

  return (
    <TextField
      InputProps={{
        startAdornment: props.adornment && (
          <InputAdornment>{props.adornment}</InputAdornment>
        ),
        endAdornment: props.endAdornment && (
          <InputAdornment>{props.endAdornment}</InputAdornment>
        ),
        inputComponent: PhoneNumberFormat,
        style: props.style ? props.style : {}
      }}
      autoComplete="off"
      className={props.className}
      disabled={props.disabled}
      error={props.isError}
      fullWidth
      helperText={!props.isError ? helperText : errorText}
      id={props.id}
      inputProps={props.inputProps}
      label={props.label}
      onChange={props.onChange}
      placeholder={props.placeholder}
      value={props.value}
      variant={props.variant ? props.variant : 'outlined'}
    />
  );
};

const dateStringFormat = (
  propsAsString,
  propsStringFormat,
  propsFormattedDate
) => {
  if (propsAsString) {
    if (propsStringFormat) return propsFormattedDate.format(propsStringFormat);
    else return propsFormattedDate.format(null);
  } else return propsFormattedDate;
};

const DatePickerInput = params => {
  const { classes, props } = params;
  const initialDate = props.value ? new Date(props.value) : null;
  let emptyLabel = !props.emptyLabel
    ? props.format.toUpperCase()
    : props.emptyLabel;
  if (initialDate) {
    emptyLabel = Moment(initialDate).format(props.format.toUpperCase());
  }

  const datePickerOnChangeHandler = date => {
    const formattedDate = Moment(date);

    if (props.endOf) formattedDate.endOf(props.endOf);
    if (props.startOf) formattedDate.startOf(props.startOf);

    // Handling on change from view
    props.onChange({
      target: {
        value: dateStringFormat(
          props.asString,
          props.stringFormat,
          formattedDate
        )
      }
    });
  };

  return (
    <>
      <FormControl
        className={props.className + ' ' + classes.datePickerContainer}
        disabled={props.disabled}
        fullWidth
        variant={props.variant ? props.variant : 'outlined'}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ marginRight: '4px' }}>
                  <img alt="icon" src="/images/assets/calendar2.svg" />
                </InputAdornment>
              )
            }}
            disabled={props.disabled}
            emptyLabel={emptyLabel}
            error={props.isError}
            floatinglabelstyle={{
              marginLeft: 10
            }}
            format={props.format}
            inputVariant={props.variant ? props.variant : 'outlined'}
            label={props.label}
            maxDate={props.maxDate}
            minDate={props.minDate}
            onChange={datePickerOnChangeHandler}
            value={props.value ? props.value : null}
            views={['month', 'year']}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      <FormHelperText
        className={classes.datePickerHelperText}
        style={{
          color: props.isError
            ? palettes[getPartnerId]?.error.main
            : palettes[getPartnerId]?.text.primary
        }}
      >
        {!props.isError ? props.helperText : props.errorText}
      </FormHelperText>
    </>
  );
};

const DatePickerInputFull = params => {
  const { classes, props } = params;
  const initialDate = props.value ? new Date(props.value) : null;
  let emptyLabel = !props.emptyLabel
    ? props.format.toUpperCase()
    : props.emptyLabel;
  if (initialDate) {
    emptyLabel = Moment(initialDate).format(props.format.toUpperCase());
  }

  const datePickerOnChangeHandler = date => {
    const formattedDate = Moment(date);

    if (props.endOf) formattedDate.endOf(props.endOf);
    if (props.startOf) formattedDate.startOf(props.startOf);

    // Handling on change from view
    props.onChange({
      target: {
        value: dateStringFormat(
          props.asString,
          props.stringFormat,
          formattedDate
        )
      }
    });
  };

  return (
    <>
      <FormControl
        className={props.className + ' ' + classes.datePickerContainer}
        disabled={props.disabled}
        fullWidth
        variant={props.variant ? props.variant : 'outlined'}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ marginRight: '4px' }}>
                  <img alt="icon" src="/images/assets/calendar2.svg" />
                </InputAdornment>
              ),
              style: props.style ? props.style : {}
            }}
            disabled={props.disabled}
            emptyLabel={emptyLabel}
            error={props.isError}
            floatinglabelstyle={{
              marginLeft: 10
            }}
            format={props.format}
            inputVariant={props.variant ? props.variant : 'outlined'}
            label={props.label}
            maxDate={props.maxDate}
            minDate={props.minDate}
            onChange={datePickerOnChangeHandler}
            value={props.value ? props.value : null}
            views={['date']}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      <FormHelperText
        className={classes.datePickerHelperText}
        style={{
          color: props.isError
            ? palettes[getPartnerId]?.error.main
            : palettes[getPartnerId]?.text.primary
        }}
      >
        {!props.isError ? props.helperText : props.errorText}
      </FormHelperText>
    </>
  );
};

const Input = props => {
  let input = null;
  const classes = useStyles();

  switch (props.inputType) {
    case 'select':
      if (props.adornment) {
        input = SelectedInputAdornment({ props, classes });
      }

      if (!props.adornment) {
        input = SelectedInput({ props, classes });
      }
      break;
    case 'select-tenor':
      input = SelectedTenor({ props, classes });
      break;
    case 'currency':
      if (props.adornment) {
        input = CurrencyInputAdornment({ props, classes });
      }

      if (!props.adornment) {
        input = CurrencyInput({ props, classes });
      }
      break;
    case 'atm-exp-date':
      input = AtmDateExpiredInput({ props, classes });
      break;
    case 'Rchecked':
      input = RcheckedInput({ props, classes });
      break;
    case 'checked':
      input = CheckedInput({ props, classes });
      break;
    case 'number':
      input = NumberInput({ props, classes });
      break;
    case 'phone-number':
      input = PhoneNumberInput({ props, classes });
      break;
    case 'acct-number':
      input = AccountNumberInput({ props, classes });
      break;
    case 'npwp-number':
      input = NpwpNumberInput({ props, classes });
      break;
    case 'date-picker':
      input = DatePickerInput({ props, classes });
      break;
    case 'date-picker-full':
      input = DatePickerInputFull({ props, classes });
      break;
    case 'phoneNumberPrefix':
      input = PhoneNumberPrefix({ props, classes });
      break;
    case 'textAreaAddress':
      input = DefaultInputTextArea({ props, classes });
      break;
    default:
      if (props.adornment) {
        input = DefaultInputAdorment({ props, classes });
      } else {
        input = DefaultInput({ props, classes });
      }
      break;
  }

  return <> {input} </>;
};

const useStyles = makeStyles(theme => ({
  labelCheck: {
    fontFamily: 'Lato',
    fontWeight: 'Normal',
    fontSize: 12,
    color: theme.palette.common.brown,
    lineHeight: '16px'
  },
  paperCheck: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 6,
    marginBottom: 6,
    border: 0
  },
  labelInput: {
    '&:disabled': {
      backgroundColor: theme.palette.common.neutralNr30
    },
    fontFamily: 'Lato',
    fontWeight: 400,
    color: theme.palette.common.neutralNr50
  },
  helperText: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '16px',
    color: theme.palette.text.secondary
  },
  errorText: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '16px',
    color: theme.palette.text.error
  },
  rp: {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: 16,
    // lineHeight: '16px',
    // margin: '0 1vw 0 0'
    marginBottom: 2,
    marginRight: 4
  },
  phone_62: {
    fontFamily: 'Lato',
    fontWeight: 600,
    fontSize: 16,
    // lineHeight: '16px',
    color: theme.palette.text.secondary,
    // margin: '0 1vw 0 0'
    marginBottom: 2,
    marginRight: 4
  },
  datePickerContainer: {
    borderRadius: 6
  },
  datePickerHelperText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: 11
  },
  inputPhoneNumber: {
    color: theme.palette.text.primary,
    borderRadius: '8px'
  }
}));

export default Input;
